import { ButtonToggleProps } from 'presentation/ui/partials/button/button-toggle/ButtonToggle';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Valuation } from 'services/device/domain/business/common/Valuation';
import { getSequenceDue, getSequenceValuation } from 'services/device/domain/business/util/SequenceUtil';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import {
	countDueDevices,
	countNoticableDevices
} from 'services/device/lib/dashboard/DeviceDashboardUtil';
import { selectFilteredClearances } from 'services/device/store/devicesSlice';
import { selectActiveSequencesByDevices } from 'services/device/store/sequenceSlice';

import { ButtonToggleSet } from 'presentation/ui/components/button/ButtonToggleSet';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { TextLink } from 'presentation/ui/partials/text-link/TextLink';
import { DashboardViewModalType } from 'presentation/view/DashboardViewModalType';
import { CardClearanceWithMeasurementGoals } from '../../../ui/compositions/card-clearance-dashboard/CardClearanceWithMeasurementGoals';

interface ClearanceCollectionProps {
	noticableClearanceCount: number;
	onClick: (modalType: DashboardViewModalType, sequence: SequenceViewModel) => void;
}

enum DeviceFilter {
	DUE = 'DUE',
	VALUATION = 'VALUATION'
}

type DeviceWithObjectives = {
	device: DeviceViewModel,
	objectives: Array<SequenceViewModel>
};
type EffectiveDevicesWithObjectives = Array<DeviceWithObjectives>;

export const ClearanceCollection = (props: ClearanceCollectionProps): JSX.Element => {
	const { noticableClearanceCount, onClick } = props;

	// Consume the contexts
	const facilityContext = useContext(FacilityContext);
	const clientContext = useContext(ClientContext);
	const l10nContext = useContext(L10nContext);

	// Read the clearance logs from the state store
	const clearances = useSelector(selectFilteredClearances(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		true,
		false
	));

	const sequencesMap = useSelector(selectActiveSequencesByDevices(clearances));

	const [clearanceFilter, setClearanceFilter] = useState<DeviceFilter>(null);
	const [dueDeviceCount, setDueDeviceCount] = useState<number>(null);
	const [noticableClearanceCollectionCount, setNoticableClearanceCollectionCount] = useState<number>(null);
	const [effectiveDevices, setEffectiveDevices] = useState<EffectiveDevicesWithObjectives>(null);

	useEffect(() => {
		setDueDeviceCount(countDueDevices(clearances, sequencesMap));
		setNoticableClearanceCollectionCount(countNoticableDevices(clearances, sequencesMap));

	}, [clearances, sequencesMap, noticableClearanceCount]);

	function filterClearanceCollectionCount() {
		const filter = async (): Promise<void> => {

			const effective: EffectiveDevicesWithObjectives = [];
			if (clearanceFilter === DeviceFilter.VALUATION) {
				for (const device of clearances) {
					if (sequencesMap.has(device.Uuid)) {
						const objective: DeviceWithObjectives = {
							device,
							objectives: []
						};
						for (const sequence of sequencesMap.get(device.Uuid)) {
							// eslint-disable-next-line no-await-in-loop
							const valuation = getSequenceValuation(sequence);
							if (valuation === Valuation.NOTICEABLE || valuation === Valuation.UNACCEPTABLE) {
								objective.objectives.push(sequence);
							}
						}
						if (objective.objectives.length > 0) {
							effective.push(objective);
						}
					}
				}
			} else if (clearanceFilter === DeviceFilter.DUE) {
				for (const device of clearances) {
					if (sequencesMap.has(device.Uuid)) {
						const objective: DeviceWithObjectives = {
							device,
							objectives: []
						};
						for (const sequence of sequencesMap.get(device.Uuid)) {
							// eslint-disable-next-line no-await-in-loop
							if (getSequenceDue(sequence)) {
								objective.objectives.push(sequence);
							}
						}
						if (objective.objectives.length > 0) {
							effective.push(objective);
						}
					}
				}
			}

			setEffectiveDevices(effective);
		};
		return filter;
	}

	useEffect(
		() => {

			const filter = filterClearanceCollectionCount();
			if (clearances !== null && sequencesMap !== null) {
				void filter();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[clearanceFilter, noticableClearanceCount]
	);

	const translations = {
		toggleButtons: {
			due: l10nContext.translate('common.buttonToggle.dashboard.due', 'Fällig'),
			flashy: l10nContext.translate('common.buttonToggle.dashboard.flashy', 'Auffällig')
		}
	};

	const clearanceFilterButtons: Array<ButtonToggleProps> = [];
	clearanceFilterButtons.push({
		buttonText: translations.toggleButtons.due,
		id: DeviceFilter.DUE,
		isActive: false,
		badge: dueDeviceCount
	});
	clearanceFilterButtons.push({
		buttonText: translations.toggleButtons.flashy,
		id: DeviceFilter.VALUATION,
		isActive: false,
		badge: noticableClearanceCollectionCount
	});

	const clearanceFilterButtonSet = clearanceFilterButtons.length > 0 ?
		<ButtonToggleSet
			buttons={clearanceFilterButtons}
			deSelectAll={false}
			onClick={(id) => {
				setClearanceFilter(id as DeviceFilter);
			}}
		/>
		:
		null;

	const renderClearanceEntries = (): JSX.Element => {
		if (effectiveDevices === null) {
			return (
				<LoadingSpinner />
			);
		}

		if (effectiveDevices.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.clearanceDashboard')} />
			);
		}

		return (
			<>
				{
					effectiveDevices.map<JSX.Element>((deviceWithObjectivs) => {
						return (
							<CardClearanceWithMeasurementGoals
								key={deviceWithObjectivs.device.Uuid}
								device={deviceWithObjectivs.device}
								sequences={deviceWithObjectivs.objectives as Array<SequenceViewModel>}
								route={Route.CLEARANCES}
								onAddRecordClick={sequence => onClick(DashboardViewModalType.CREATE_RECORD, sequence)}
							/>
						);
					})
				}
			</>
		);
	};

	return (
		<Expandable>
			<ExpandableHeader caption={l10nContext.translate('view.dashboard.headline.clearances', 'Freimessungen')}>
				<TextLink
					text={l10nContext.translate('common.textlink.all', 'alle')}
					target="/clearances"
				/>
				{clearanceFilterButtonSet}
			</ExpandableHeader>
			<ExpandableBody>
				<CardCollectionLayout>
					{renderClearanceEntries()}
				</CardCollectionLayout>
			</ExpandableBody>
		</Expandable>
	);
};
