/* eslint-disable max-classes-per-file */
import { ChartDescription } from '../common/description/ChartDescription';
import { DetailsDescription } from '../common/description/DetailsDescription';
import { FieldDescription } from '../common/description/FieldDescription';
import { TableDescription } from '../common/description/TableDescription';
import { MemoryStoreData } from '../common/memory/MemoryData';
import { Optional } from '../common/misc/Optional';
import { Record } from '../common/record/Record';
import { RecordViewData } from '../common/record/RecordData';
import { RecordDefinition } from '../common/record/RecordDefinition';
import { Sequence } from '../common/sequence/Sequence';
import { SequenceViewData } from '../common/sequence/SequenceData';
import { SequenceDefinition } from '../common/sequence/SequenceDefinition';
import { SequenceTypeDefinitionProxy } from '../common/sequence/SequenceTypeDefinitionProxy';
import { BackgroundCountBariumSequenceTypeDefinitionProxy } from '../inventory/sequence/BackgroundCountBarium';
import { BackgroundCountCaesiumSequenceTypeDefinitionProxy } from '../inventory/sequence/BackgroundCountCaesium';
import { BackgroundCountCobaltSequenceTypeDefinitionProxy } from '../inventory/sequence/BackgroundCountCobalt';
import { BackgroundCountIntegralSequenceTypeDefinitionProxy } from '../inventory/sequence/BackgroundCountIntegral';
import { BackgroundCountIodineSequenceTypeDefinitionProxy } from '../inventory/sequence/BackgroundCountIodine';
import { BackgroundCountTechnetiumSequenceTypeDefinitionProxy } from '../inventory/sequence/BackgroundCountTechnetium';
import { BackgroundZeroEffectSequenceTypeDefinitionProxy } from '../inventory/sequence/BackgroundZeroEffect';
import { BackgroundZeroEffectFreeSequenceTypeDefinitionProxy } from '../inventory/sequence/BackgroundZeroEffectFree';
import { BackgroundZeroEffectV2SequenceTypeDefinitionProxy } from '../inventory/sequence/BackgroundZeroEffectV2';
import { BoreholeFactorSequenceTypeDefinitionProxy } from '../inventory/sequence/BoreholeFactor';
import { BoreholeFactorPayloadSequenceTypeDefinitionProxy } from '../inventory/sequence/BoreholeFactorPayload';
import { CheckSequenceTypeDefinitionProxy } from '../inventory/sequence/Check';
import { CtdiSequenceTypeDefinitionProxy } from '../inventory/sequence/Ctdi';
import { CtdiCustomSequenceTypeDefinitionProxy } from '../inventory/sequence/CtdiCustom';
import { DoseIndicatorSequenceTypeDefinitionProxy } from '../inventory/sequence/DoseIndicator';
import { DoseSurfaceProductAbsoluteSequenceTypeDefinitionProxy } from '../inventory/sequence/DoseSurfaceProductAbsolute';
import { DoseSurfaceProductRelativeSequenceTypeDefinitionProxy } from '../inventory/sequence/DoseSurfaceProductRelative';
import { EdgeDeviationSequenceTypeDefinitionProxy } from '../inventory/sequence/EdgeDeviation';
import { ExtrinsicHomogeneitySequenceTypeDefinitionProxy } from '../inventory/sequence/ExtrinsicHomogeneity';
import { ExtrinsicHomogeneityV2SequenceTypeDefinitionProxy } from '../inventory/sequence/ExtrinsicHomogeneityV2';
import { ExtrinsicHomogeneityV3SequenceTypeDefinitionProxy } from '../inventory/sequence/ExtrinsicHomogeneityV3';
import { ExtrinsicHomogeneityV4SequenceTypeDefinitionProxy } from '../inventory/sequence/ExtrinsicHomogeneityV4';
import { FreeTextSequenceTypeDefinitionProxy } from '../inventory/sequence/FreeText';
import { GenericCustomSequenceTypeDefinitionProxy } from '../inventory/sequence/GenericCustom';
import { GeometryFactorDeterminationSequenceTypeDefinitionProxy } from '../inventory/sequence/GeometryFactorDetermination';
import { HeliumLevelSequenceTypeDefinitionProxy } from '../inventory/sequence/HeliumLevel';
import { HomogeneitySequenceTypeDefinitionProxy } from '../inventory/sequence/Homogeneity';
import { HomogeneityWithoutCorrectionMatrixSequenceTypeDefinitionProxy } from '../inventory/sequence/HomogeneityWithoutCorrectionMatrix';
import { IntrinsicHomogeneitySequenceTypeDefinitionProxy } from '../inventory/sequence/IntrinsicHomogeneity';
import { IntrinsicHomogeneityV2SequenceTypeDefinitionProxy } from '../inventory/sequence/IntrinsicHomogeneityV2';
import { IntrinsicHomogeneityV3SequenceTypeDefinitionProxy } from '../inventory/sequence/IntrinsicHomogeneityV3';
import { LineResolutionSequenceTypeDefinitionProxy } from '../inventory/sequence/LineResolution';
import { LinearityFluorineSequenceTypeDefinitionProxy } from '../inventory/sequence/LinearityFluorine';
import { LinearityTechnetiumSequenceTypeDefinitionProxy } from '../inventory/sequence/LinearityTechnetium';
import { LinearityV2FluorineSequenceTypeDefinitionProxy } from '../inventory/sequence/LinearityV2Fluorine';
import { LinearityV2TechnetiumSequenceTypeDefinitionProxy } from '../inventory/sequence/LinearityV2Technetium';
import { LowContrastObjectsSequenceTypeDefinitionProxy } from '../inventory/sequence/LowContrastObjects';
import { MolybdenumBreakthroughMolybdenumSequenceTypeDefinitionProxy } from '../inventory/sequence/MolybdenumBreakthroughMolybdenum';
import { MolybdenumBreakthroughPayloadSequenceTypeDefinitionProxy } from '../inventory/sequence/MolybdenumBreakthroughPayload';
import { MolybdenumBreakthroughTechnetiumSequenceTypeDefinitionProxy } from '../inventory/sequence/MolybdenumBreakthroughTechnetium';
import { NuclidePositionResponsivenessSequenceTypeDefinitionProxy } from '../inventory/sequence/NuclidePositionResponsiveness';
import { NuclidePositionZeroEffectSequenceTypeDefinitionProxy } from '../inventory/sequence/NuclidePositionZeroEffect';
import { NuclidePositionZeroEffect802SequenceTypeDefinitionProxy } from '../inventory/sequence/NuclidePositionZeroEffect802';
import { ObjectiveSequenceTypeDefinitionProxy } from '../inventory/sequence/Objective';
import { PetCtPhilipsGeminiFwhmAverageSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsGeminiFwhmAverage';
import { PetCtPhilipsGeminiLargestMaxMinDeltaTiltSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsGeminiLargestMaxMinDeltaTilt';
import { PetCtPhilipsGeminiMaxBaselineDifferenceSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsGeminiMaxBaselineDifference';
import { PetCtPhilipsGeminiSuvValidationAndHomogenitySequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsGeminiSuvValidationAndHomogenity';
import { PetCtPhilipsVereosAxialAberranceSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsVereosAxialAberrance';
import { PetCtPhilipsVereosEnergyTestAverageCentroidSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsVereosEnergyTestAverageCentroid';
import { PetCtPhilipsVereosEnergyTestAverageEnergyResolutionSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsVereosEnergyTestAverageEnergyResolution';
import { PetCtPhilipsVereosIntegralHomogenitySequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsVereosIntegralHomogenity';
import { PetCtPhilipsVereosMidSuvOfHomogenitySequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsVereosMidSuvOfHomogenity';
import { PetCtPhilipsVereosMidSuvOfHomogenityBrainSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsVereosMidSuvOfHomogenityBrain';
import { PetCtPhilipsVereosTimingResolutionSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsVereosTimingResolution';
import { PetCtPhilipsVereosUniformityTestBadPixelsSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtPhilipsVereosUniformityTestBadPixels';
import { PetCtSiemensGenericBlockEfficiencySequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtSiemensGenericBlockEfficiency';
import { PetCtSiemensGenericBlockNoiseSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtSiemensGenericBlockNoise';
import { PetCtSiemensGenericMeasuredRandomsSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtSiemensGenericMeasuredRandoms';
import { PetCtSiemensGenericScannerEfficiencySequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtSiemensGenericScannerEfficiency';
import { PetCtSiemensGenericScatterRatioSequenceTypeDefinitionProxy } from '../inventory/sequence/PetCtSiemensGenericScatterRatio';
import { PowerWindowSequenceTypeDefinitionProxy } from '../inventory/sequence/PowerWindow';
import { PowerWindowPercentageSequenceTypeDefinitionProxy } from '../inventory/sequence/PowerWindowPercentage';
import { PowerWindowPercentageV2SequenceTypeDefinitionProxy } from '../inventory/sequence/PowerWindowPercentageV2';
import { PowerWindowSettingSequenceTypeDefinitionProxy } from '../inventory/sequence/PowerWindowSetting';
import { ResponsivenessSequenceTypeDefinitionProxy } from '../inventory/sequence/Responsiveness';
import { ResponsivenessCs137SequenceTypeDefinitionProxy } from '../inventory/sequence/ResponsivenessCs137';
import { ResponsivenessCs137V2SequenceTypeDefinitionProxy } from '../inventory/sequence/ResponsivenessCs137V2';
import { RpCartridgeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCartridge';
import { RpCuriumNanocisSingleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumNanocisSingleStripe';
import { RpCuriumNeuroliteSingleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumNeuroliteSingleStripe';
import { RpCuriumPulmocisFilterSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumPulmocisFilter';
import { RpCuriumScintimunSingleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumScintimunSingleStripe';
import { RpCuriumStamicisCartridgeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumStamicisCartridge';
import { RpCuriumStamicisSingleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumStamicisSingleStripe';
import { RpCuriumTeceosDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumTeceosDoubleStripe';
import { RpCuriumTeceosDoubleStripe2024SequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumTeceosDoubleStripe2024';
import { RpCuriumTechnescanDsmaSingleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumTechnescanDsmaSingleStripe';
import { RpCuriumTechnescanDtpaDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumTechnescanDtpaDoubleStripe';
import { RpCuriumTechnescanHdpDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumTechnescanHdpDoubleStripe';
import { RpCuriumTechnescanMag3CartridgeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumTechnescanMag3Cartridge';
import { RpCuriumTechnescanMag3CartridgeV2SequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumTechnescanMag3CartridgeV2';
import { RpCuriumTechnescanSestamibiCartridgeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumTechnescanSestamibiCartridge';
import { RpCuriumTechnescanSestamibiSingleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpCuriumTechnescanSestamibiSingleStripe';
import { RpDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpDoubleStripe';
import { RpFilterSequenceTypeDefinitionProxy } from '../inventory/sequence/RpFilter';
import { RpRinsedFilterSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRinsedFilter';
import { RpRotopB20SingleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopB20SingleStripe';
import { RpRotopCardiotopDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopCardiotopDoubleStripe';
import { RpRotopDmsaSingleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopDmsaSingleStripe';
import { RpRotopDtpaDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopDtpaDoubleStripe';
import { RpRotopEhidaDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopEhidaDoubleStripe';
import { RpRotopMag3DoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopMag3DoubleStripe';
import { RpRotopMdpDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopMdpDoubleStripe';
import { RpRotopNanoHsaSingleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopNanoHsaSingleStripe';
import { RpRotopNeurospectDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopNeurospectDoubleStripe';
import { RpRotopTektrotydDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopTektrotydDoubleStripe';
import { RpRotopTetrofosminDoubleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpRotopTetrofosminDoubleStripe';
import { RpSingleStripeSequenceTypeDefinitionProxy } from '../inventory/sequence/RpSingleStripe';
import { RpTlcScannerSequenceTypeDefinitionProxy } from '../inventory/sequence/RpTlcScanner';
import { SingleSelectSequenceTypeDefinitionProxy } from '../inventory/sequence/SingleSelect';
import { YieldSequenceTypeDefinitionProxy } from '../inventory/sequence/Yield';
import { YieldBariumSequenceTypeDefinitionProxy } from '../inventory/sequence/YieldBarium';
import { YieldCaesiumSequenceTypeDefinitionProxy } from '../inventory/sequence/YieldCaesium';
import { YieldCobaltSequenceTypeDefinitionProxy } from '../inventory/sequence/YieldCobalt';
import { YieldIodineSequenceTypeDefinitionProxy } from '../inventory/sequence/YieldIodine';
import { YieldIodineV2SequenceTypeDefinitionProxy } from '../inventory/sequence/YieldIodineV2';
import { YieldPayloadSequenceTypeDefinitionProxy } from '../inventory/sequence/YieldPayload';
import { YieldPercentSequenceTypeDefinitionProxy } from '../inventory/sequence/YieldPercent';
import { YieldStrontiumSequenceTypeDefinitionProxy } from '../inventory/sequence/YieldStrontium';
import { YieldTechnetiumSequenceTypeDefinitionProxy } from '../inventory/sequence/YieldTechnetium';
import { DeviceType } from './DeviceType';
import {
	DeviceTypeDefinition,
	getDeviceTypeDefinitions
} from './DeviceTypeDefinition';
import { ProductGroup } from './ProductGroup';
import {
	ProductGroupDefinition,
	getProductGroupDefinitions
} from './ProductGroupDefinition';
import { SequenceType } from './SequenceType';

export class SequenceTypeDefinition {

	private readonly sequenceType: SequenceType;

	private readonly labelKey: string;

	private readonly productGroups: ReadonlyArray<ProductGroup>;

	private readonly deviceTypes: ReadonlyArray<DeviceType>;

	private readonly proxy: SequenceTypeDefinitionProxy;

	private readonly demonstration: boolean;

	private readonly deprecated: boolean;

	constructor(sequenceType: SequenceType, labelKey: string, productGroups: ReadonlyArray<ProductGroup>, deviceTypes: ReadonlyArray<DeviceType>, proxy: SequenceTypeDefinitionProxy, demonstration: boolean, deprecated: boolean) {
		this.sequenceType = sequenceType;
		this.labelKey = labelKey;
		this.productGroups = productGroups;
		this.deviceTypes = deviceTypes;
		this.proxy = proxy;
		this.demonstration = demonstration;
		this.deprecated = deprecated;
	}

	public getSequenceType(): SequenceType {
		return this.sequenceType;
	}

	public getLabelKey(): string {
		return this.labelKey;
	}

	public getProductGroups(): ReadonlyArray<ProductGroup> {
		return this.productGroups;
	}

	public getProductGroupDefinitions(): ReadonlyArray<ProductGroupDefinition> {
		return getProductGroupDefinitions(this.productGroups);
	}

	public getDeviceTypes(): ReadonlyArray<DeviceType> {
		return this.deviceTypes;
	}

	public getDeviceTypeDefinitions(): ReadonlyArray<DeviceTypeDefinition> {
		return getDeviceTypeDefinitions(this.deviceTypes);
	}

	public getValueDescription(): FieldDescription {
		return this.proxy.getValueDescription();
	}

	public getDetailsDescription(): DetailsDescription {
		return this.proxy.getDetailsDescription();
	}

	public getChartDescriptions(): ReadonlyArray<ChartDescription> {
		return this.proxy.getChartDescriptions();
	}

	public getTableDescriptions(): ReadonlyArray<TableDescription> {
		return this.proxy.getTableDescriptions();
	}

	public getSequenceDefinition(deviceType: DeviceType): SequenceDefinition {
		return this.proxy.getSequenceDefinition(deviceType);
	}

	public getRecordDefinition(sequence: Sequence, memoryStoreData: MemoryStoreData): RecordDefinition {
		return this.proxy.getRecordDefinition(sequence, memoryStoreData);
	}

	public restoreSequenceFromViewData(sequenceViewData: SequenceViewData): Sequence {
		return this.proxy.restoreSequenceFromViewData(sequenceViewData);
	}

	public restoreRecordFromViewData(recordViewData: RecordViewData): Record {
		return this.proxy.restoreRecordFromViewData(recordViewData);
	}

	public isDemonstration(): boolean {
		return this.demonstration;
	}

	public isDeprecated(): boolean {
		return this.deprecated;
	}

	public toString = (): string => {
		return this.sequenceType.toString();
	}

}

export const BACKGROUND_COUNT_BARIUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BACKGROUND_COUNT_BARIUM,
	'inventory.sequenceType.backgroundCountBarium',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new BackgroundCountBariumSequenceTypeDefinitionProxy(),
	false,
	false
);

export const BACKGROUND_COUNT_CAESIUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BACKGROUND_COUNT_CAESIUM,
	'inventory.sequenceType.backgroundCountCaesium',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS,
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new BackgroundCountCaesiumSequenceTypeDefinitionProxy(),
	false,
	false
);

export const BACKGROUND_COUNT_COBALT: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BACKGROUND_COUNT_COBALT,
	'inventory.sequenceType.backgroundCountCobalt',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new BackgroundCountCobaltSequenceTypeDefinitionProxy(),
	false,
	false
);

export const BACKGROUND_COUNT_IODINE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BACKGROUND_COUNT_IODINE,
	'inventory.sequenceType.backgroundCountIodine',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new BackgroundCountIodineSequenceTypeDefinitionProxy(),
	false,
	false
);

export const BACKGROUND_COUNT_TECHNETIUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BACKGROUND_COUNT_TECHNETIUM,
	'inventory.sequenceType.backgroundCountTechnetium',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS,
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new BackgroundCountTechnetiumSequenceTypeDefinitionProxy(),
	false,
	false
);

export const BACKGROUND_COUNT_INTEGRAL: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BACKGROUND_COUNT_INTEGRAL,
	'inventory.sequenceType.backgroundCountIntegral',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new BackgroundCountIntegralSequenceTypeDefinitionProxy(),
	false,
	false
);

export const BOREHOLE_FACTOR: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BOREHOLE_FACTOR,
	'inventory.sequenceType.boreholeFactor',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new BoreholeFactorSequenceTypeDefinitionProxy(),
	false,
	false
);

export const BOREHOLE_FACTOR_PAYLOAD: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BOREHOLE_FACTOR_PAYLOAD,
	'inventory.sequenceType.boreholeFactorPayload',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new BoreholeFactorPayloadSequenceTypeDefinitionProxy(),
	false,
	false
);

export const LINEARITY_FLUORINE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.LINEARITY_FLUORINE,
	'inventory.sequenceType.linearityFluorine',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.ACTIVIMETER
	],
	new LinearityFluorineSequenceTypeDefinitionProxy(),
	false,
	true
);

export const LINEARITY_TECHNETIUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.LINEARITY_TECHNETIUM,
	'inventory.sequenceType.linearityTechnetium',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.ACTIVIMETER
	],
	new LinearityTechnetiumSequenceTypeDefinitionProxy(),
	false,
	true
);

export const LINEARITY_V2_FLUORINE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.LINEARITY_V2_FLUORINE,
	'inventory.sequenceType.linearityV2Fluorine',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.ACTIVIMETER
	],
	new LinearityV2FluorineSequenceTypeDefinitionProxy(),
	false,
	false
);

export const LINEARITY_V2_TECHNETIUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.LINEARITY_V2_TECHNETIUM,
	'inventory.sequenceType.linearityV2Technetium',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.ACTIVIMETER
	],
	new LinearityV2TechnetiumSequenceTypeDefinitionProxy(),
	false,
	false
);

export const NUCLIDE_POSITION_ZERO_EFFECT: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.NUCLIDE_POSITION_ZERO_EFFECT,
	'inventory.sequenceType.nuclidePositionZeroEffect',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.ACTIVIMETER
	],
	new NuclidePositionZeroEffectSequenceTypeDefinitionProxy(),
	false,
	true
);

export const NUCLIDE_POSITION_ZERO_EFFECT_802: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.NUCLIDE_POSITION_ZERO_EFFECT_802,
	'inventory.sequenceType.nuclidePositionZeroEffect802',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.ACTIVIMETER
	],
	new NuclidePositionZeroEffect802SequenceTypeDefinitionProxy(),
	false,
	false
);

export const NUCLIDE_POSITION_RESPONSIVENESS: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.NUCLIDE_POSITION_RESPONSIVENESS,
	'inventory.sequenceType.nuclidePositionResponsiveness',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.ACTIVIMETER
	],
	new NuclidePositionResponsivenessSequenceTypeDefinitionProxy(),
	false,
	false
);

export const MOLYBDENUM_BREAKTHROUGH_MOLYBDENUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.MOLYBDENUM_BREAKTHROUGH_MOLYBDENUM,
	'inventory.sequenceType.molybdenumBreakthroughMolybdenum',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.ACTIVIMETER
	],
	new MolybdenumBreakthroughMolybdenumSequenceTypeDefinitionProxy(),
	false,
	false
);

export const MOLYBDENUM_BREAKTHROUGH_TECHNETIUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.MOLYBDENUM_BREAKTHROUGH_TECHNETIUM,
	'inventory.sequenceType.molybdenumBreakthroughTechnetium',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.ACTIVIMETER
	],
	new MolybdenumBreakthroughTechnetiumSequenceTypeDefinitionProxy(),
	false,
	false
);

export const MOLYBDENUM_BREAKTHROUGH_PAYLOAD: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.MOLYBDENUM_BREAKTHROUGH_PAYLOAD,
	'inventory.sequenceType.molybdenumBreakthroughPayload',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.ACTIVIMETER
	],
	new MolybdenumBreakthroughPayloadSequenceTypeDefinitionProxy(),
	false,
	false
);

export const POWER_WINDOW: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.POWER_WINDOW,
	'inventory.sequenceType.powerWindow',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new PowerWindowSequenceTypeDefinitionProxy(),
	false,
	false
);

export const POWER_WINDOW_PERCENTAGE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.POWER_WINDOW_PERCENTAGE,
	'inventory.sequenceType.powerWindowPercentage',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new PowerWindowPercentageSequenceTypeDefinitionProxy(),
	false,
	true
);

export const POWER_WINDOW_PERCENTAGE_V2: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.POWER_WINDOW_PERCENTAGE_V2,
	'inventory.sequenceType.powerWindowPercentageV2',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new PowerWindowPercentageV2SequenceTypeDefinitionProxy(),
	false,
	false
);

export const POWER_WINDOW_SETTING: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.POWER_WINDOW_SETTING,
	'inventory.sequenceType.powerWindowSetting',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new PowerWindowSettingSequenceTypeDefinitionProxy(),
	false,
	false
);

export const YIELD: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.YIELD,
	'inventory.sequenceType.yield',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new YieldSequenceTypeDefinitionProxy(),
	false,
	true
);

export const YIELD_BARIUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.YIELD_BARIUM,
	'inventory.sequenceType.yieldBarium',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new YieldBariumSequenceTypeDefinitionProxy(),
	false,
	true
);

export const YIELD_CAESIUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.YIELD_CAESIUM,
	'inventory.sequenceType.yieldCaesium',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new YieldCaesiumSequenceTypeDefinitionProxy(),
	false,
	true
);

export const YIELD_COBALT: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.YIELD_COBALT,
	'inventory.sequenceType.yieldCobalt',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new YieldCobaltSequenceTypeDefinitionProxy(),
	false,
	false
);

export const YIELD_IODINE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.YIELD_IODINE,
	'inventory.sequenceType.yieldIodine',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new YieldIodineSequenceTypeDefinitionProxy(),
	false,
	true
);

export const YIELD_IODINE_V2: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.YIELD_IODINE_V2,
	'inventory.sequenceType.yieldIodineV2',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new YieldIodineV2SequenceTypeDefinitionProxy(),
	false,
	false
);

export const YIELD_STRONTIUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.YIELD_STRONTIUM,
	'inventory.sequenceType.yieldStrontium',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS,
		DeviceType.CONTAMINATION_MONITOR
	],
	new YieldStrontiumSequenceTypeDefinitionProxy(),
	false,
	true
);

export const YIELD_TECHNETIUM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.YIELD_TECHNETIUM,
	'inventory.sequenceType.yieldTechnetium',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new YieldTechnetiumSequenceTypeDefinitionProxy(),
	false,
	false
);

export const YIELD_PAYLOAD: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.YIELD_PAYLOAD,
	'inventory.sequenceType.yieldPayload',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new YieldPayloadSequenceTypeDefinitionProxy(),
	false,
	false
);

export const YIELD_PERCENT: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.YIELD_PERCENT,
	'inventory.sequenceType.yieldPercent',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new YieldPercentSequenceTypeDefinitionProxy(),
	false,
	false
);

export const GEOMETRY_FACTOR_DETERMINATION: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.GEOMETRY_FACTOR_DETERMINATION,
	'inventory.sequenceType.geometryFactorDetermination',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS,
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new GeometryFactorDeterminationSequenceTypeDefinitionProxy(),
	false,
	false
);

export const HOMOGENEITY: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.HOMOGENEITY,
	'inventory.sequenceType.homogeneity',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_PLANAR
	],
	new HomogeneitySequenceTypeDefinitionProxy(),
	false,
	false
);

export const EXTRINSIC_HOMOGENEITY: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.EXTRINSIC_HOMOGENEITY,
	'inventory.sequenceType.extrinsicHomogeneity',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new ExtrinsicHomogeneitySequenceTypeDefinitionProxy(),
	false,
	true
);

export const EXTRINSIC_HOMOGENEITY_V2: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.EXTRINSIC_HOMOGENEITY_V2,
	'inventory.sequenceType.extrinsicHomogeneityV2',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new ExtrinsicHomogeneityV2SequenceTypeDefinitionProxy(),
	false,
	true
);

export const EXTRINSIC_HOMOGENEITY_V3: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.EXTRINSIC_HOMOGENEITY_V3,
	'inventory.sequenceType.extrinsicHomogeneityV3',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new ExtrinsicHomogeneityV3SequenceTypeDefinitionProxy(),
	false,
	true
);

export const EXTRINSIC_HOMOGENEITY_V4: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.EXTRINSIC_HOMOGENEITY_V4,
	'inventory.sequenceType.extrinsicHomogeneityV4',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new ExtrinsicHomogeneityV4SequenceTypeDefinitionProxy(),
	false,
	false
);

export const INTRINSIC_HOMOGENEITY: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.INTRINSIC_HOMOGENEITY,
	'inventory.sequenceType.intrinsicHomogeneity',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new IntrinsicHomogeneitySequenceTypeDefinitionProxy(),
	false,
	true
);

export const INTRINSIC_HOMOGENEITY_V2: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.INTRINSIC_HOMOGENEITY_V2,
	'inventory.sequenceType.intrinsicHomogeneityV2',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new IntrinsicHomogeneityV2SequenceTypeDefinitionProxy(),
	false,
	true
);

export const INTRINSIC_HOMOGENEITY_V3: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.INTRINSIC_HOMOGENEITY_V3,
	'inventory.sequenceType.intrinsicHomogeneityV3',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new IntrinsicHomogeneityV3SequenceTypeDefinitionProxy(),
	false,
	false
);

export const HOMOGENEITY_WITHOUT_CORRECTION_MATRIX: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.HOMOGENEITY_WITHOUT_CORRECTION_MATRIX,
	'inventory.sequenceType.homogeneityWithoutCorrectionMatrix',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.SPECT_CT
	],
	new HomogeneityWithoutCorrectionMatrixSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_SINGLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_SINGLE_STRIPE,
	'inventory.sequenceType.rpSingleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpSingleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_DOUBLE_STRIPE,
	'inventory.sequenceType.rpDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CARTRIDGE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CARTRIDGE,
	'inventory.sequenceType.rpCartridge',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCartridgeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_FILTER: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_FILTER,
	'inventory.sequenceType.rpFilter',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpFilterSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_RINSED_FILTER: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_RINSED_FILTER,
	'inventory.sequenceType.rpRinsedFilter',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRinsedFilterSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_TLC_SCANNER: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_TLC_SCANNER,
	'inventory.sequenceType.rpTlcScanner',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpTlcScannerSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_B20_SINGLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_B20_SINGLE_STRIPE,
	'inventory.sequenceType.rpRotopB20SingleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopB20SingleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_CARDIOTOP_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_CARDIOTOP_DOUBLE_STRIPE,
	'inventory.sequenceType.rpRotopCardiotopDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopCardiotopDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_DMSA_SINGLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_DMSA_SINGLE_STRIPE,
	'inventory.sequenceType.rpRotopDmsaSingleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopDmsaSingleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_DTPA_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_DTPA_DOUBLE_STRIPE,
	'inventory.sequenceType.rpRotopDtpaDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopDtpaDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_EHIDA_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_EHIDA_DOUBLE_STRIPE,
	'inventory.sequenceType.rpRotopEhidaDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopEhidaDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_MAG3_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_MAG3_DOUBLE_STRIPE,
	'inventory.sequenceType.rpRotopMag3DoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopMag3DoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_MDP_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_MDP_DOUBLE_STRIPE,
	'inventory.sequenceType.rpRotopMdpDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopMdpDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_NANO_HSA_SINGLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_NANO_HSA_SINGLE_STRIPE,
	'inventory.sequenceType.rpRotopNanoHsaSingleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopNanoHsaSingleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_NEUROSPECT_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_NEUROSPECT_DOUBLE_STRIPE,
	'inventory.sequenceType.rpRotopNeurospectDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopNeurospectDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_TEKTROTYD_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_TEKTROTYD_DOUBLE_STRIPE,
	'inventory.sequenceType.rpRotopTektrotydDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopTektrotydDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_ROTOP_TETROFOSMIN_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_ROTOP_TETROFOSMIN_DOUBLE_STRIPE,
	'inventory.sequenceType.rpRotopTetrofosminDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpRotopTetrofosminDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_NANOCIS_SINGLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_NANOCIS_SINGLE_STRIPE,
	'inventory.sequenceType.rpCuriumNanocisSingleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumNanocisSingleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_NEUROLITE_SINGLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_NEUROLITE_SINGLE_STRIPE,
	'inventory.sequenceType.rpCuriumNeuroliteSingleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumNeuroliteSingleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_PULMOCIS_FILTER: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_PULMOCIS_FILTER,
	'inventory.sequenceType.rpCuriumPulmocisFilter',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumPulmocisFilterSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_SCINTIMUN_SINGLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_SCINTIMUN_SINGLE_STRIPE,
	'inventory.sequenceType.rpCuriumScintimunSingleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumScintimunSingleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_STAMICIS_SINGLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_STAMICIS_SINGLE_STRIPE,
	'inventory.sequenceType.rpCuriumStamicisSingleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumStamicisSingleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_STAMICIS_CARTRIDGE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_STAMICIS_CARTRIDGE,
	'inventory.sequenceType.rpCuriumStamicisCartridge',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumStamicisCartridgeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_TECEOS_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_TECEOS_DOUBLE_STRIPE,
	'inventory.sequenceType.rpCuriumTeceosDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumTeceosDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	true
);

export const RP_CURIUM_TECEOS_DOUBLE_STRIPE_2024: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_TECEOS_DOUBLE_STRIPE_2024,
	'inventory.sequenceType.rpCuriumTeceosDoubleStripe2024',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumTeceosDoubleStripe2024SequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_TECHNESCAN_DSMA_SINGLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_TECHNESCAN_DSMA_SINGLE_STRIPE,
	'inventory.sequenceType.rpCuriumTechnescanDsmaSingleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumTechnescanDsmaSingleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_TECHNESCAN_DTPA_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_TECHNESCAN_DTPA_DOUBLE_STRIPE,
	'inventory.sequenceType.rpCuriumTechnescanDtpaDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumTechnescanDtpaDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_TECHNESCAN_HDP_DOUBLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_TECHNESCAN_HDP_DOUBLE_STRIPE,
	'inventory.sequenceType.rpCuriumTechnescanHdpDoubleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumTechnescanHdpDoubleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE,
	'inventory.sequenceType.rpCuriumTechnescanMag3Cartridge',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumTechnescanMag3CartridgeSequenceTypeDefinitionProxy(),
	false,
	true
);

export const RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE_V2: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE_V2,
	'inventory.sequenceType.rpCuriumTechnescanMag3CartridgeV2',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumTechnescanMag3CartridgeV2SequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_TECHNESCAN_SESTAMIBI_SINGLE_STRIPE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_TECHNESCAN_SESTAMIBI_SINGLE_STRIPE,
	'inventory.sequenceType.rpCuriumTechnescanSestamibiSingleStripe',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumTechnescanSestamibiSingleStripeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RP_CURIUM_TECHNESCAN_SESTAMIBI_CARTRIDGE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RP_CURIUM_TECHNESCAN_SESTAMIBI_CARTRIDGE,
	'inventory.sequenceType.rpCuriumTechnescanSestamibiCartridge',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.CURIUM
	],
	[
		DeviceType.RADIOCHEMICAL_PURITY
	],
	new RpCuriumTechnescanSestamibiCartridgeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const CTDI: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.CTDI,
	'inventory.sequenceType.ctdi',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.RADIOLOGY
	],
	[
		DeviceType.PET_CT_PHILIPS_VEREOS,
		DeviceType.PET_CT_PHILIPS_GEMINI,
		DeviceType.PET_CT_SIEMENS_GENERIC,
		DeviceType.CT,
		DeviceType.SPECT_CT
	],
	new CtdiSequenceTypeDefinitionProxy(),
	false,
	false
);

export const CTDI_CUSTOM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.CTDI_CUSTOM,
	'inventory.sequenceType.ctdiCustom',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.RADIOLOGY
	],
	[
		DeviceType.PET_CT_PHILIPS_VEREOS,
		DeviceType.PET_CT_PHILIPS_GEMINI,
		DeviceType.PET_CT_SIEMENS_GENERIC,
		DeviceType.CT,
		DeviceType.SPECT_CT
	],
	new CtdiCustomSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_GEMINI_FWHM_AVERAGE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_GEMINI_FWHM_AVERAGE,
	'inventory.sequenceType.petCtPhilipsGeminiFwhmAverage',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_GEMINI
	],
	new PetCtPhilipsGeminiFwhmAverageSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_GEMINI_LARGEST_MAX_MIN_DELTA_TILT: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_GEMINI_LARGEST_MAX_MIN_DELTA_TILT,
	'inventory.sequenceType.petCtPhilipsGeminiLargestMaxMinDeltaTilt',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_GEMINI
	],
	new PetCtPhilipsGeminiLargestMaxMinDeltaTiltSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_GEMINI_MAX_BASELINE_DIFFERENCE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_GEMINI_MAX_BASELINE_DIFFERENCE,
	'inventory.sequenceType.petCtPhilipsGeminiMaxBaselineDifference',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_GEMINI
	],
	new PetCtPhilipsGeminiMaxBaselineDifferenceSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_GEMINI_SUV_VALIDATION_AND_HOMOGENITY: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_GEMINI_SUV_VALIDATION_AND_HOMOGENITY,
	'inventory.sequenceType.petCtPhilipsGeminiSuvValidationAndHomogenity',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_GEMINI
	],
	new PetCtPhilipsGeminiSuvValidationAndHomogenitySequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_VEREOS_AXIAL_ABERRANCE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_VEREOS_AXIAL_ABERRANCE,
	'inventory.sequenceType.petCtPhilipsVereosAxialAberrance',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_VEREOS
	],
	new PetCtPhilipsVereosAxialAberranceSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_CENTROID: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_CENTROID,
	'inventory.sequenceType.petCtPhilipsVereosEnergyTestAverageCentroid',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_VEREOS
	],
	new PetCtPhilipsVereosEnergyTestAverageCentroidSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_ENERGY_RESOLUTION: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_ENERGY_RESOLUTION,
	'inventory.sequenceType.petCtPhilipsVereosEnergyTestAverageEnergyResolution',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_VEREOS
	],
	new PetCtPhilipsVereosEnergyTestAverageEnergyResolutionSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_VEREOS_INTEGRAL_HOMOGENITY: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_VEREOS_INTEGRAL_HOMOGENITY,
	'inventory.sequenceType.petCtPhilipsVereosIntegralHomogenity',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_VEREOS
	],
	new PetCtPhilipsVereosIntegralHomogenitySequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY,
	'inventory.sequenceType.petCtPhilipsVereosMidSuvOfHomogenity',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_VEREOS
	],
	new PetCtPhilipsVereosMidSuvOfHomogenitySequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY_BRAIN: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY_BRAIN,
	'inventory.sequenceType.petCtPhilipsVereosMidSuvOfHomogenityBrain',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_VEREOS
	],
	new PetCtPhilipsVereosMidSuvOfHomogenityBrainSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_VEREOS_TIMING_RESOLUTION: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_VEREOS_TIMING_RESOLUTION,
	'inventory.sequenceType.petCtPhilipsVereosTimingResolution',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_VEREOS
	],
	new PetCtPhilipsVereosTimingResolutionSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_PHILIPS_VEREOS_UNIFORMITY_TEST_BAD_PIXELS: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_PHILIPS_VEREOS_UNIFORMITY_TEST_BAD_PIXELS,
	'inventory.sequenceType.petCtPhilipsVereosUniformityTestBadPixels',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_PHILIPS_VEREOS
	],
	new PetCtPhilipsVereosUniformityTestBadPixelsSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_SIEMENS_GENERIC_BLOCK_EFFICIENCY: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_SIEMENS_GENERIC_BLOCK_EFFICIENCY,
	'inventory.sequenceType.petCtSiemensGenericBlockEfficiency',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_SIEMENS_GENERIC
	],
	new PetCtSiemensGenericBlockEfficiencySequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_SIEMENS_GENERIC_BLOCK_NOISE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_SIEMENS_GENERIC_BLOCK_NOISE,
	'inventory.sequenceType.petCtSiemensGenericBlockNoise',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_SIEMENS_GENERIC
	],
	new PetCtSiemensGenericBlockNoiseSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_SIEMENS_GENERIC_MEASURED_RANDOMS: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_SIEMENS_GENERIC_MEASURED_RANDOMS,
	'inventory.sequenceType.petCtSiemensGenericMeasuredRandoms',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_SIEMENS_GENERIC
	],
	new PetCtSiemensGenericMeasuredRandomsSequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_SIEMENS_GENERIC_SCANNER_EFFICIENCY: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_SIEMENS_GENERIC_SCANNER_EFFICIENCY,
	'inventory.sequenceType.petCtSiemensGenericScannerEfficiency',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_SIEMENS_GENERIC
	],
	new PetCtSiemensGenericScannerEfficiencySequenceTypeDefinitionProxy(),
	false,
	false
);

export const PET_CT_SIEMENS_GENERIC_SCATTER_RATIO: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.PET_CT_SIEMENS_GENERIC_SCATTER_RATIO,
	'inventory.sequenceType.petCtSiemensGenericScatterRatio',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PET_CT_SIEMENS_GENERIC
	],
	new PetCtSiemensGenericScatterRatioSequenceTypeDefinitionProxy(),
	false,
	false
);

export const BACKGROUND_ZERO_EFFECT: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BACKGROUND_ZERO_EFFECT,
	'inventory.sequenceType.backgroundZeroEffect',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.SURGICAL_PROBE
	],
	new BackgroundZeroEffectSequenceTypeDefinitionProxy(),
	false,
	false
);

export const BACKGROUND_ZERO_EFFECT_V2: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BACKGROUND_ZERO_EFFECT_V2,
	'inventory.sequenceType.backgroundZeroEffectV2',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.CONTAMINATION_MONITOR
	],
	new BackgroundZeroEffectV2SequenceTypeDefinitionProxy(),
	false,
	false
);

export const BACKGROUND_ZERO_EFFECT_FREE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.BACKGROUND_ZERO_EFFECT_FREE,
	'inventory.sequenceType.backgroundZeroEffectFree',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.CONTAMINATION_MONITOR
	],
	new BackgroundZeroEffectFreeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RESPONSIVENESS: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RESPONSIVENESS,
	'inventory.sequenceType.responsiveness',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.SURGICAL_PROBE
	],
	new ResponsivenessSequenceTypeDefinitionProxy(),
	false,
	false
);

export const RESPONSIVENESS_CS_137: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RESPONSIVENESS_CS_137,
	'inventory.sequenceType.responsivenessCs137',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new ResponsivenessCs137SequenceTypeDefinitionProxy(),
	false,
	true
);

export const RESPONSIVENESS_CS_137_V2: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.RESPONSIVENESS_CS_137_V2,
	'inventory.sequenceType.responsivenessCs137V2',
	[
		ProductGroup.NUCLEAR_MEDICINE
	],
	[
		DeviceType.PROBE_TESTERS
	],
	new ResponsivenessCs137V2SequenceTypeDefinitionProxy(),
	false,
	false
);

export const HELIUM_LEVEL: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.HELIUM_LEVEL,
	'inventory.sequenceType.heliumLevel',
	[
		ProductGroup.RADIOLOGY
	],
	[
		DeviceType.MRT
	],
	new HeliumLevelSequenceTypeDefinitionProxy(),
	false,
	false
);

export const LOW_CONTRAST_OBJECTS: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.LOW_CONTRAST_OBJECTS,
	'inventory.sequenceType.lowContrastObjects',
	[
		ProductGroup.RADIOLOGY
	],
	[
		DeviceType.X_RAY_IMAGE_PLATE,
		DeviceType.X_RAY_DIGITAL
	],
	new LowContrastObjectsSequenceTypeDefinitionProxy(),
	false,
	false
);

export const LINE_RESOLUTION: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.LINE_RESOLUTION,
	'inventory.sequenceType.lineResolution',
	[
		ProductGroup.RADIOLOGY
	],
	[
		DeviceType.X_RAY_IMAGE_PLATE,
		DeviceType.X_RAY_DIGITAL
	],
	new LineResolutionSequenceTypeDefinitionProxy(),
	false,
	false
);

export const DOSE_SURFACE_PRODUCT_ABSOLUTE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.DOSE_SURFACE_PRODUCT_ABSOLUTE,
	'inventory.sequenceType.doseSurfaceProductAbsolute',
	[
		ProductGroup.RADIOLOGY
	],
	[
		DeviceType.X_RAY_IMAGE_PLATE,
		DeviceType.X_RAY_DIGITAL
	],
	new DoseSurfaceProductAbsoluteSequenceTypeDefinitionProxy(),
	false,
	false
);

export const DOSE_SURFACE_PRODUCT_RELATIVE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.DOSE_SURFACE_PRODUCT_RELATIVE,
	'inventory.sequenceType.doseSurfaceProductRelative',
	[
		ProductGroup.RADIOLOGY
	],
	[
		DeviceType.X_RAY_IMAGE_PLATE,
		DeviceType.X_RAY_DIGITAL
	],
	new DoseSurfaceProductRelativeSequenceTypeDefinitionProxy(),
	false,
	false
);

export const DOSE_INDICATOR: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.DOSE_INDICATOR,
	'inventory.sequenceType.doseIndicator',
	[
		ProductGroup.RADIOLOGY
	],
	[
		DeviceType.X_RAY_IMAGE_PLATE,
		DeviceType.X_RAY_DIGITAL
	],
	new DoseIndicatorSequenceTypeDefinitionProxy(),
	false,
	false
);

export const EDGE_DEVIATION: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.EDGE_DEVIATION,
	'inventory.sequenceType.edgeDeviation',
	[
		ProductGroup.RADIOLOGY
	],
	[
		DeviceType.X_RAY_IMAGE_PLATE,
		DeviceType.X_RAY_DIGITAL
	],
	new EdgeDeviationSequenceTypeDefinitionProxy(),
	false,
	false
);

export const GENERIC_CUSTOM: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.GENERIC_CUSTOM,
	'inventory.sequenceType.genericCustom',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.RADIOLOGY,
		ProductGroup.GENERIC_SEQUENCES
	],
	[
		DeviceType.ACTIVIMETER,
		DeviceType.PROBE_TESTERS,
		DeviceType.SURGICAL_PROBE,
		DeviceType.CONTAMINATION_MONITOR,
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.RADIOCHEMICAL_PURITY,
		DeviceType.PET_CT_PHILIPS_GEMINI,
		DeviceType.PET_CT_PHILIPS_VEREOS,
		DeviceType.PET_CT_SIEMENS_GENERIC,
		DeviceType.SPECT_CT,
		DeviceType.SPECT_CT,
		DeviceType.CT,
		DeviceType.MRT,
		DeviceType.X_RAY_IMAGE_PLATE,
		DeviceType.X_RAY_DIGITAL,
		DeviceType.PICTURE_REPRODUCTION,
		DeviceType.GENERIC
	],
	new GenericCustomSequenceTypeDefinitionProxy(),
	false,
	false
);

export const FREE_TEXT: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.FREE_TEXT,
	'inventory.sequenceType.freeText',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.RADIOLOGY,
		ProductGroup.GENERIC_SEQUENCES
	],
	[
		DeviceType.ACTIVIMETER,
		DeviceType.PROBE_TESTERS,
		DeviceType.SURGICAL_PROBE,
		DeviceType.CONTAMINATION_MONITOR,
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.RADIOCHEMICAL_PURITY,
		DeviceType.PET_CT_PHILIPS_GEMINI,
		DeviceType.PET_CT_PHILIPS_VEREOS,
		DeviceType.PET_CT_SIEMENS_GENERIC,
		DeviceType.SPECT_CT,
		DeviceType.SPECT_CT,
		DeviceType.CT,
		DeviceType.MRT,
		DeviceType.X_RAY_IMAGE_PLATE,
		DeviceType.X_RAY_DIGITAL,
		DeviceType.PICTURE_REPRODUCTION,
		DeviceType.GENERIC
	],
	new FreeTextSequenceTypeDefinitionProxy(),
	false,
	false
);

export const SINGLE_SELECT: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.SINGLE_SELECT,
	'inventory.sequenceType.singleSelect',
	[
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.RADIOLOGY,
		ProductGroup.GENERIC_SEQUENCES
	],
	[
		DeviceType.ACTIVIMETER,
		DeviceType.PROBE_TESTERS,
		DeviceType.SURGICAL_PROBE,
		DeviceType.CONTAMINATION_MONITOR,
		DeviceType.GAMMA_CAMERA_PLANAR,
		DeviceType.GAMMA_CAMERA_FOR_SPECT,
		DeviceType.RADIOCHEMICAL_PURITY,
		DeviceType.PET_CT_PHILIPS_GEMINI,
		DeviceType.PET_CT_PHILIPS_VEREOS,
		DeviceType.PET_CT_SIEMENS_GENERIC,
		DeviceType.SPECT_CT,
		DeviceType.SPECT_CT,
		DeviceType.CT,
		DeviceType.MRT,
		DeviceType.X_RAY_IMAGE_PLATE,
		DeviceType.X_RAY_DIGITAL,
		DeviceType.PICTURE_REPRODUCTION,
		DeviceType.GENERIC
	],
	new SingleSelectSequenceTypeDefinitionProxy(),
	false,
	false
);

export const CHECK: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.CHECK,
	'inventory.sequenceType.check',
	[
		ProductGroup.CLEARANCE,
		ProductGroup.GENERIC_SEQUENCES
	],
	[
		DeviceType.CLEARANCE
	],
	new CheckSequenceTypeDefinitionProxy(),
	false,
	false
);

export const OBJECTIVE: SequenceTypeDefinition = new SequenceTypeDefinition(
	SequenceType.OBJECTIVE,
	'inventory.sequenceType.objective',
	[
		ProductGroup.CLEARANCE,
		ProductGroup.GENERIC_SEQUENCES
	],
	[
		DeviceType.CLEARANCE
	],
	new ObjectiveSequenceTypeDefinitionProxy(),
	false,
	false
);

export function getSequenceTypeDefinition(sequenceType: SequenceType): SequenceTypeDefinition {
	const sequenceTypeDefinition: Optional<SequenceTypeDefinition> = getAllSequenceTypeDefinitions().find((definition) => definition.getSequenceType() === sequenceType) ?? null;
	if (sequenceTypeDefinition === null) {
		throw new Error('Missing sequence type definition: ' + sequenceType);
	}
	return sequenceTypeDefinition;
}

export function getSequenceTypeDefinitions(sequenceTypes: ReadonlyArray<SequenceType>): ReadonlyArray<SequenceTypeDefinition> {
	return sequenceTypes.map((sequenceType) => getSequenceTypeDefinition(sequenceType));
}

export function getSequenceDefinition(deviceType: DeviceType, sequenceType: SequenceType): SequenceDefinition {
	return getSequenceTypeDefinition(sequenceType).getSequenceDefinition(deviceType);
}

export function getAllSequenceTypeDefinitions(): ReadonlyArray<SequenceTypeDefinition> {
	return [
		BACKGROUND_COUNT_BARIUM,
		BACKGROUND_COUNT_CAESIUM,
		BACKGROUND_COUNT_COBALT,
		BACKGROUND_COUNT_IODINE,
		BACKGROUND_COUNT_TECHNETIUM,
		BACKGROUND_COUNT_INTEGRAL,
		BOREHOLE_FACTOR,
		BOREHOLE_FACTOR_PAYLOAD,
		LINEARITY_FLUORINE,
		LINEARITY_TECHNETIUM,
		LINEARITY_V2_FLUORINE,
		LINEARITY_V2_TECHNETIUM,
		NUCLIDE_POSITION_ZERO_EFFECT,
		NUCLIDE_POSITION_ZERO_EFFECT_802,
		NUCLIDE_POSITION_RESPONSIVENESS,
		MOLYBDENUM_BREAKTHROUGH_MOLYBDENUM,
		MOLYBDENUM_BREAKTHROUGH_TECHNETIUM,
		MOLYBDENUM_BREAKTHROUGH_PAYLOAD,
		POWER_WINDOW,
		POWER_WINDOW_PERCENTAGE,
		POWER_WINDOW_PERCENTAGE_V2,
		POWER_WINDOW_SETTING,
		YIELD,
		YIELD_BARIUM,
		YIELD_CAESIUM,
		YIELD_COBALT,
		YIELD_IODINE,
		YIELD_IODINE_V2,
		YIELD_STRONTIUM,
		YIELD_TECHNETIUM,
		YIELD_PAYLOAD,
		YIELD_PERCENT,
		GEOMETRY_FACTOR_DETERMINATION,
		HOMOGENEITY,
		EXTRINSIC_HOMOGENEITY,
		EXTRINSIC_HOMOGENEITY_V2,
		EXTRINSIC_HOMOGENEITY_V3,
		EXTRINSIC_HOMOGENEITY_V4,
		INTRINSIC_HOMOGENEITY,
		INTRINSIC_HOMOGENEITY_V2,
		INTRINSIC_HOMOGENEITY_V3,
		HOMOGENEITY_WITHOUT_CORRECTION_MATRIX,
		RP_SINGLE_STRIPE,
		RP_DOUBLE_STRIPE,
		RP_CARTRIDGE,
		RP_FILTER,
		RP_RINSED_FILTER,
		RP_TLC_SCANNER,
		RP_ROTOP_B20_SINGLE_STRIPE,
		RP_ROTOP_CARDIOTOP_DOUBLE_STRIPE,
		RP_ROTOP_DMSA_SINGLE_STRIPE,
		RP_ROTOP_DTPA_DOUBLE_STRIPE,
		RP_ROTOP_EHIDA_DOUBLE_STRIPE,
		RP_ROTOP_MAG3_DOUBLE_STRIPE,
		RP_ROTOP_MDP_DOUBLE_STRIPE,
		RP_ROTOP_NANO_HSA_SINGLE_STRIPE,
		RP_ROTOP_NEUROSPECT_DOUBLE_STRIPE,
		RP_ROTOP_TEKTROTYD_DOUBLE_STRIPE,
		RP_ROTOP_TETROFOSMIN_DOUBLE_STRIPE,
		RP_CURIUM_NANOCIS_SINGLE_STRIPE,
		RP_CURIUM_NEUROLITE_SINGLE_STRIPE,
		RP_CURIUM_PULMOCIS_FILTER,
		RP_CURIUM_SCINTIMUN_SINGLE_STRIPE,
		RP_CURIUM_STAMICIS_SINGLE_STRIPE,
		RP_CURIUM_STAMICIS_CARTRIDGE,
		RP_CURIUM_TECEOS_DOUBLE_STRIPE,
		RP_CURIUM_TECEOS_DOUBLE_STRIPE_2024,
		RP_CURIUM_TECHNESCAN_DSMA_SINGLE_STRIPE,
		RP_CURIUM_TECHNESCAN_DTPA_DOUBLE_STRIPE,
		RP_CURIUM_TECHNESCAN_HDP_DOUBLE_STRIPE,
		RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE,
		RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE_V2,
		RP_CURIUM_TECHNESCAN_SESTAMIBI_SINGLE_STRIPE,
		RP_CURIUM_TECHNESCAN_SESTAMIBI_CARTRIDGE,
		CTDI,
		CTDI_CUSTOM,
		PET_CT_PHILIPS_GEMINI_FWHM_AVERAGE,
		PET_CT_PHILIPS_GEMINI_LARGEST_MAX_MIN_DELTA_TILT,
		PET_CT_PHILIPS_GEMINI_MAX_BASELINE_DIFFERENCE,
		PET_CT_PHILIPS_GEMINI_SUV_VALIDATION_AND_HOMOGENITY,
		PET_CT_PHILIPS_VEREOS_AXIAL_ABERRANCE,
		PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_CENTROID,
		PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_ENERGY_RESOLUTION,
		PET_CT_PHILIPS_VEREOS_INTEGRAL_HOMOGENITY,
		PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY,
		PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY_BRAIN,
		PET_CT_PHILIPS_VEREOS_TIMING_RESOLUTION,
		PET_CT_PHILIPS_VEREOS_UNIFORMITY_TEST_BAD_PIXELS,
		PET_CT_SIEMENS_GENERIC_BLOCK_EFFICIENCY,
		PET_CT_SIEMENS_GENERIC_BLOCK_NOISE,
		PET_CT_SIEMENS_GENERIC_MEASURED_RANDOMS,
		PET_CT_SIEMENS_GENERIC_SCANNER_EFFICIENCY,
		PET_CT_SIEMENS_GENERIC_SCATTER_RATIO,
		BACKGROUND_ZERO_EFFECT,
		BACKGROUND_ZERO_EFFECT_V2,
		BACKGROUND_ZERO_EFFECT_FREE,
		RESPONSIVENESS,
		RESPONSIVENESS_CS_137,
		RESPONSIVENESS_CS_137_V2,
		HELIUM_LEVEL,
		LOW_CONTRAST_OBJECTS,
		LINE_RESOLUTION,
		DOSE_SURFACE_PRODUCT_ABSOLUTE,
		DOSE_SURFACE_PRODUCT_RELATIVE,
		DOSE_INDICATOR,
		EDGE_DEVIATION,
		GENERIC_CUSTOM,
		FREE_TEXT,
		SINGLE_SELECT,
		CHECK,
		OBJECTIVE
	];
}

export function getAllSequenceTypes(): ReadonlyArray<SequenceType> {
	return [
		SequenceType.BACKGROUND_COUNT_BARIUM,
		SequenceType.BACKGROUND_COUNT_CAESIUM,
		SequenceType.BACKGROUND_COUNT_COBALT,
		SequenceType.BACKGROUND_COUNT_IODINE,
		SequenceType.BACKGROUND_COUNT_TECHNETIUM,
		SequenceType.BACKGROUND_COUNT_INTEGRAL,
		SequenceType.BOREHOLE_FACTOR,
		SequenceType.BOREHOLE_FACTOR_PAYLOAD,
		SequenceType.LINEARITY_FLUORINE,
		SequenceType.LINEARITY_TECHNETIUM,
		SequenceType.LINEARITY_V2_FLUORINE,
		SequenceType.LINEARITY_V2_TECHNETIUM,
		SequenceType.NUCLIDE_POSITION_ZERO_EFFECT,
		SequenceType.NUCLIDE_POSITION_ZERO_EFFECT_802,
		SequenceType.NUCLIDE_POSITION_RESPONSIVENESS,
		SequenceType.MOLYBDENUM_BREAKTHROUGH_MOLYBDENUM,
		SequenceType.MOLYBDENUM_BREAKTHROUGH_TECHNETIUM,
		SequenceType.MOLYBDENUM_BREAKTHROUGH_PAYLOAD,
		SequenceType.POWER_WINDOW,
		SequenceType.POWER_WINDOW_PERCENTAGE,
		SequenceType.POWER_WINDOW_PERCENTAGE_V2,
		SequenceType.POWER_WINDOW_SETTING,
		SequenceType.YIELD,
		SequenceType.YIELD_BARIUM,
		SequenceType.YIELD_CAESIUM,
		SequenceType.YIELD_COBALT,
		SequenceType.YIELD_IODINE,
		SequenceType.YIELD_IODINE_V2,
		SequenceType.YIELD_STRONTIUM,
		SequenceType.YIELD_TECHNETIUM,
		SequenceType.YIELD_PAYLOAD,
		SequenceType.YIELD_PERCENT,
		SequenceType.GEOMETRY_FACTOR_DETERMINATION,
		SequenceType.HOMOGENEITY,
		SequenceType.EXTRINSIC_HOMOGENEITY,
		SequenceType.EXTRINSIC_HOMOGENEITY_V2,
		SequenceType.EXTRINSIC_HOMOGENEITY_V3,
		SequenceType.EXTRINSIC_HOMOGENEITY_V4,
		SequenceType.INTRINSIC_HOMOGENEITY,
		SequenceType.INTRINSIC_HOMOGENEITY_V2,
		SequenceType.INTRINSIC_HOMOGENEITY_V3,
		SequenceType.HOMOGENEITY_WITHOUT_CORRECTION_MATRIX,
		SequenceType.RP_SINGLE_STRIPE,
		SequenceType.RP_DOUBLE_STRIPE,
		SequenceType.RP_CARTRIDGE,
		SequenceType.RP_FILTER,
		SequenceType.RP_RINSED_FILTER,
		SequenceType.RP_TLC_SCANNER,
		SequenceType.RP_ROTOP_B20_SINGLE_STRIPE,
		SequenceType.RP_ROTOP_CARDIOTOP_DOUBLE_STRIPE,
		SequenceType.RP_ROTOP_DMSA_SINGLE_STRIPE,
		SequenceType.RP_ROTOP_DTPA_DOUBLE_STRIPE,
		SequenceType.RP_ROTOP_EHIDA_DOUBLE_STRIPE,
		SequenceType.RP_ROTOP_MAG3_DOUBLE_STRIPE,
		SequenceType.RP_ROTOP_MDP_DOUBLE_STRIPE,
		SequenceType.RP_ROTOP_NANO_HSA_SINGLE_STRIPE,
		SequenceType.RP_ROTOP_NEUROSPECT_DOUBLE_STRIPE,
		SequenceType.RP_ROTOP_TEKTROTYD_DOUBLE_STRIPE,
		SequenceType.RP_ROTOP_TETROFOSMIN_DOUBLE_STRIPE,
		SequenceType.RP_CURIUM_NANOCIS_SINGLE_STRIPE,
		SequenceType.RP_CURIUM_NEUROLITE_SINGLE_STRIPE,
		SequenceType.RP_CURIUM_PULMOCIS_FILTER,
		SequenceType.RP_CURIUM_SCINTIMUN_SINGLE_STRIPE,
		SequenceType.RP_CURIUM_STAMICIS_SINGLE_STRIPE,
		SequenceType.RP_CURIUM_STAMICIS_CARTRIDGE,
		SequenceType.RP_CURIUM_TECEOS_DOUBLE_STRIPE,
		SequenceType.RP_CURIUM_TECEOS_DOUBLE_STRIPE_2024,
		SequenceType.RP_CURIUM_TECHNESCAN_DSMA_SINGLE_STRIPE,
		SequenceType.RP_CURIUM_TECHNESCAN_DTPA_DOUBLE_STRIPE,
		SequenceType.RP_CURIUM_TECHNESCAN_HDP_DOUBLE_STRIPE,
		SequenceType.RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE,
		SequenceType.RP_CURIUM_TECHNESCAN_MAG3_CARTRIDGE_V2,
		SequenceType.RP_CURIUM_TECHNESCAN_SESTAMIBI_SINGLE_STRIPE,
		SequenceType.RP_CURIUM_TECHNESCAN_SESTAMIBI_CARTRIDGE,
		SequenceType.CTDI,
		SequenceType.CTDI_CUSTOM,
		SequenceType.PET_CT_PHILIPS_GEMINI_FWHM_AVERAGE,
		SequenceType.PET_CT_PHILIPS_GEMINI_LARGEST_MAX_MIN_DELTA_TILT,
		SequenceType.PET_CT_PHILIPS_GEMINI_MAX_BASELINE_DIFFERENCE,
		SequenceType.PET_CT_PHILIPS_GEMINI_SUV_VALIDATION_AND_HOMOGENITY,
		SequenceType.PET_CT_PHILIPS_VEREOS_AXIAL_ABERRANCE,
		SequenceType.PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_CENTROID,
		SequenceType.PET_CT_PHILIPS_VEREOS_ENERGY_TEST_AVERAGE_ENERGY_RESOLUTION,
		SequenceType.PET_CT_PHILIPS_VEREOS_INTEGRAL_HOMOGENITY,
		SequenceType.PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY,
		SequenceType.PET_CT_PHILIPS_VEREOS_MID_SUV_OF_HOMOGENITY_BRAIN,
		SequenceType.PET_CT_PHILIPS_VEREOS_TIMING_RESOLUTION,
		SequenceType.PET_CT_PHILIPS_VEREOS_UNIFORMITY_TEST_BAD_PIXELS,
		SequenceType.PET_CT_SIEMENS_GENERIC_BLOCK_EFFICIENCY,
		SequenceType.PET_CT_SIEMENS_GENERIC_BLOCK_NOISE,
		SequenceType.PET_CT_SIEMENS_GENERIC_MEASURED_RANDOMS,
		SequenceType.PET_CT_SIEMENS_GENERIC_SCANNER_EFFICIENCY,
		SequenceType.PET_CT_SIEMENS_GENERIC_SCATTER_RATIO,
		SequenceType.BACKGROUND_ZERO_EFFECT,
		SequenceType.BACKGROUND_ZERO_EFFECT_V2,
		SequenceType.BACKGROUND_ZERO_EFFECT_FREE,
		SequenceType.RESPONSIVENESS,
		SequenceType.RESPONSIVENESS_CS_137,
		SequenceType.RESPONSIVENESS_CS_137_V2,
		SequenceType.HELIUM_LEVEL,
		SequenceType.LOW_CONTRAST_OBJECTS,
		SequenceType.LINE_RESOLUTION,
		SequenceType.DOSE_SURFACE_PRODUCT_ABSOLUTE,
		SequenceType.DOSE_SURFACE_PRODUCT_RELATIVE,
		SequenceType.DOSE_INDICATOR,
		SequenceType.EDGE_DEVIATION,
		SequenceType.GENERIC_CUSTOM,
		SequenceType.FREE_TEXT,
		SequenceType.SINGLE_SELECT,
		SequenceType.CHECK,
		SequenceType.OBJECTIVE
	];
}