import { L10nContext } from 'context/L10nContext';
import { CardDeviceList } from 'presentation/ui/components/cards/card-device/card-device-list/CardDeviceList';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { CardItemSingleControl } from 'presentation/ui/partials/card/card-item-controls/card-item-single-control/CardItemSingleControl';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Route } from 'router/Route';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { getSequenceTypeDefinition } from 'services/device/domain/business/inventory/SequenceTypeDefinition';
import { SequenceModelState } from 'services/device/domain/model/SequenceModelState';
import { Guid } from '../../../../lib/guid/Guid';
import {
	CardMaintenanceLogDetail
} from '../../../../presentation/ui/components/cards/card-maintenance-log/card-maintenance-log-detail/CardMaintenanceLogDetail';
import {
	CardItemMaintenanceLogAttributes
} from '../../../../presentation/ui/partials/card/card-item-attributes/card-item-maintenance-log-attributes/CardItemMaintenanceLogAttributes';
import { CardItemControlActionType } from '../../../../presentation/ui/partials/card/card-item-controls/CardItemControlType';
import {
	CardItemMaintenanceLogHeader
} from '../../../../presentation/ui/partials/card/card-item-header/card-item-maintenance-log-header/CardItemMaintenanceLogHeader';
import { IconIdentifier } from '../../../../presentation/ui/partials/icon/IconIdentifier';
import { IllustrationIdentifier } from '../../../../presentation/ui/partials/illustration/IllustrationIdentifier';
import { TagType } from '../../../../presentation/ui/partials/tag/Tag';
import { SimpleViewTitle } from '../../../core/presentation/ui/simple-view-title/SimpleViewTitle';
import { DeviceType } from '../../../device/domain/business/inventory/DeviceType';
import { selectExternalReportDeviceByUuid } from '../../../device/store/externalReportDevicesSlice';
import { selectExternalReportSequencesByDevice } from '../../../device/store/externalReportSequenceSlice';
import { DocumentViewModel } from '../../../documents/domain/model/DocumentModel';
import { DocumentModelScope } from '../../../documents/domain/model/DocumentModelScope';
import { DocumentModalType } from '../../../documents/domain/type/DocumentsModalType';
import { DocumentEntry } from '../../../documents/presentation/ui/document-entry/DocumentEntry';
import { DocumentModal } from '../../../documents/presentation/ui/document-modal/DocumentModal';
import { resetActionStatus as documentResetActionStatus } from '../../../documents/store/documentSlice';
import { selectExternalReportDocumentsByDevice } from '../../../documents/store/externalReportDocumentSlice';
import { MaintenanceLogEntryModelState } from '../../../maintenance-log/domain/model/MaintenanceLogEntryModelState';
import {
	selectExternalReportMaintenanceLogEntriesByDevice
} from '../../../maintenance-log/store/externalReportMaintenanceLogEntrySlice';
import { resetActionStatus } from '../../../maintenance-log/store/maintenanceLogEntrySlice';
import { selectExternalReport } from '../../store/externalReportSlice';
import { ReportDeviceCard } from '../ui/report-device-card/ReportDeviceCard';

interface Params {
	reportUuid: string,
	deviceUuid: string
}

export const ReportDeviceView = (): JSX.Element => {

	const history = useHistory();

	const dispatch = useDispatch();

	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Unwrap the required report uuid from the route
	const params = useParams<Params>();
	const reportUuid = params?.reportUuid ?? null;
	if (reportUuid === null) {
		throw new Error('Report id is missing');
	}

	const deviceUuid = params?.deviceUuid ?? null;
	if (deviceUuid === null) {
		throw new Error('Device id is missing');
	}

	const externalReport = useSelector(selectExternalReport(reportUuid));

	const device = useSelector(selectExternalReportDeviceByUuid(deviceUuid));
	const sequences = useSelector(selectExternalReportSequencesByDevice(deviceUuid));
	const documents = useSelector(selectExternalReportDocumentsByDevice(deviceUuid));
	const maintenanceLogEntriesByDevice = useSelector(selectExternalReportMaintenanceLogEntriesByDevice(deviceUuid));

	const reportDocumentsUuids = externalReport?.ReportDocuments.map((reportDocument) => {
		return reportDocument.Document;
	});

	const filteredDocuments = documents?.filter((document) => {
		return reportDocumentsUuids?.includes(document.Uuid);
	});

	const [selectedDocument, setSelectedDocument] = useState<DocumentViewModel>(null);
	const [modalDocumentType, setModalDocumentType] = useState<DocumentModalType>(null);

	const resetModal = (): void => {
		dispatch(resetActionStatus());
		dispatch(documentResetActionStatus());
		setModalDocumentType(null);
		setSelectedDocument(null);
	};

	const handleDocumentDownload = (_actionType: CardItemControlActionType, documentViewModel: DocumentViewModel): void => {
		resetModal();
		setSelectedDocument(documentViewModel);

		setModalDocumentType(DocumentModalType.EXTERNAL_DOWNLOAD);
	};

	const renderSequences = (): JSX.Element => {
		if (sequences.length === 0) {
			if (device.Type === DeviceType.CLEARANCE) {
				return (
					<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.clearanceSequence', 'Keine Mitarbeiter/Messziele')} />
				);
			}
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.sequence', 'Keine Messreihen')} />
			);
		}

		const sequenceCards = sequences.map((sequence) => (

			<CardDeviceList
				key={sequence.Uuid}
				archived={sequence.State === SequenceModelState.ARCHIVED}
			>
				<CardItemHeader
					title={sequence.Name}
					subtitle={l10nContext.translate(getSequenceTypeDefinition(sequence.Type).getLabelKey())}
					archived={sequence.State === SequenceModelState.ARCHIVED}
				/>
				<CardItemSingleControl
					cardId={sequence.Uuid}
					actionDetails={CardItemControlState.ENABLED}
					onClick={device.Type === DeviceType.CLEARANCE ?
						() => history.push(`${Route.EXTERNAL_REPORT}/${externalReport?.Uuid}${Route.CLEARANCES}/${device.Uuid}${Route.MEASUREMENT_GOALS}/${sequence.Uuid}`) :
						() => history.push(`${Route.EXTERNAL_REPORT}/${externalReport?.Uuid}${Route.DEVICES}/${device.Uuid}${Route.SEQUENCES}/${sequence.Uuid}`)}
				/>
			</CardDeviceList>
		));

		return (
			<>
				{sequenceCards}
			</>
		);
	};

	const renderDocuments = (): JSX.Element => {
		if (filteredDocuments.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.reportDocuments', 'Keine Dokumente')} />
			);
		}

		const documentCards = filteredDocuments.map((document) => (

			<DocumentEntry
				key={document.Uuid}
				isReportView
				documentEntry={document}
				onActionClick={(action, downloadDocument) => {
					handleDocumentDownload(action, downloadDocument);
				}}
			/>
		));

		return (
			<>
				{documentCards}
			</>
		);
	};

	const deviceCard =
		<ReportDeviceCard
			key={device.Uuid}
			device={device}
			location={facilityContext.availableFacility(device?.Facility)?.Name ?? '-'}
		/>;

	const renderMaintenanceLogEntriesFacility = (): Array<JSX.Element> => {
		let cards = [];

		if (maintenanceLogEntriesByDevice && maintenanceLogEntriesByDevice.length) {
			cards = maintenanceLogEntriesByDevice.map((maintenanceLogEntry) => {
				const headerTag = {
					label: maintenanceLogEntry.State === MaintenanceLogEntryModelState.ONGOING ? l10nContext.translate('maintenanceLog.status.open', 'offen') : l10nContext.translate('maintenanceLog.status.resolved', 'abgeschlossen'),
					type: maintenanceLogEntry.State === MaintenanceLogEntryModelState.ONGOING ? TagType.NEGATIVE : TagType.POSITIVE,
					icon: IconIdentifier.EDIT
				};
				return (
					<CardMaintenanceLogDetail key={maintenanceLogEntry.Uuid}>
						<CardItemMaintenanceLogHeader
							title={maintenanceLogEntry.IncidentTitle}
							illustration={IllustrationIdentifier.OPERATING_LOG_GAMKAMERA_PLANAR}
							tag={headerTag}
						/>
						<CardItemMaintenanceLogAttributes
							incidentCause={maintenanceLogEntry.IncidentCause}
							recordDate={maintenanceLogEntry.IncidentDateStart}
							recorder={maintenanceLogEntry.ReporterName}
							solvedDate={maintenanceLogEntry.IncidentDateEnd}
							recorderSolved={maintenanceLogEntry.SolverName}
							activities={maintenanceLogEntry.IncidentSolution}
						/>
					</CardMaintenanceLogDetail>
				);
			});
		} else {
			cards.push(
				<CardEmpty
					key={Guid.generate()}
					message={l10nContext.translate('common.cards.reportsMaintenanceLogEntries', 'Keine Betriebsbucheinträge für diesen Zeitraum vorhanden')}
				/>
			);
		}
		return cards;
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs mapURLFragments={[[reportUuid, externalReport?.Name], [deviceUuid, device?.Name]]} />
				</NavigationSecondary>
			</TopbarLayoutSection>
			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						{deviceCard}
					</ViewLayoutSection>

					<ViewLayoutSection>
						<SimpleViewTitle
							label={device.Type === DeviceType.CLEARANCE ?
								l10nContext.translate('view.reports.reportMeasurementGoalsCollection', 'Messziele') :
								l10nContext.translate('view.reports.reportSequencesCollection', 'Messreihen')}
							isReportView
						/>
					</ViewLayoutSection>
					<ViewLayoutSection>
						<CardCollectionLayout>
							{renderSequences()}
						</CardCollectionLayout>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<SimpleViewTitle
							label={l10nContext.translate('view.reports.reportDocuments', 'Dokumente')}
							isReportView
						/>
					</ViewLayoutSection>
					<ViewLayoutSection>
						<CardCollectionLayout>
							<DocumentModal
								modalType={modalDocumentType}
								selectedDocument={selectedDocument}
								onDismiss={resetModal}
								documentScope={DocumentModelScope.DEVICE}
								documentScopeReference={device.Uuid}
							/>
							{renderDocuments()}
						</CardCollectionLayout>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<SimpleViewTitle
							label={l10nContext.translate('view.reports.reportFacilityMaintenanceLogEntriesDevice', 'Betriebsbucheinträge zum Gerät')}
							isReportView
						/>
					</ViewLayoutSection>
					<ViewLayoutSection>
						<CardCollectionLayout>
							{renderMaintenanceLogEntriesFacility()}
						</CardCollectionLayout>
					</ViewLayoutSection>

				</ViewLayout>
			</MainLayoutSection>
		</MainLayout>
	);
};
