import { L10nContext } from 'context/L10nContext';

import { MediaQuery, useMediaQuery } from 'presentation/hooks';
import { ButtonGhost, IconPosition } from 'presentation/ui/partials/button/button-ghost/ButtonGhost';
import { ButtonIcon, ButtonIconShape, ButtonIconWeight } from 'presentation/ui/partials/button/button-icon/ButtonIcon';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Select, SelectOpenDirection } from 'presentation/ui/partials/input/select/Select';
import { NavigationElementPrimary } from 'presentation/ui/partials/navigation/navigation-primary/NavigationElementPrimary';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Route } from 'router/Route';
import { AuthContext } from 'services/core/context/AuthContext';

import {
	permissionGroupCyclotronProduct,
	permissionGroupDevice,
	permissionGroupDocument,
	permissionGroupGenerator,
	permissionGroupMaintenanceLog,
	permissionGroupMembership,
	permissionGroupReport
} from 'services/core/lib/auth/AuthService';

import './navigation.scss';
import { ClientContext } from '../../../../../services/core/context/ClientContext';

/**
 * Primary navigation component
 */
export const Navigation = (): JSX.Element => {

	const l10nContext = useContext(L10nContext);
	const authContext = useContext(AuthContext);
	const clientContext = useContext(ClientContext);

	const { pathname } = useLocation();

	const [openState, setOpenState] = useState<boolean>(false);

	// Saving chosed language in local storage
	const choosedLanguage = (locale: string) => {
		l10nContext.changeLanguage(locale);
		localStorage.setItem('locale_' + authContext.getActor().Uuid, locale);
	};

	const matchedMediaQuery = useMediaQuery(MediaQuery.XL);

	useEffect(() => {
		setOpenState(matchedMediaQuery);
	}, [matchedMediaQuery]);

	useEffect(() => {
		const locale = localStorage.getItem('locale_' + authContext.getActor().Uuid);
		locale !== null ? l10nContext.changeLanguage(locale) : l10nContext.changeLanguage('de');
	}, [authContext, l10nContext]);

	const localeOptions = [
		{
			label: l10nContext.translate('navigation.german', 'Deutsch'),
			value: 'de',
			checked: l10nContext.language === 'de'
		},
		{
			label: l10nContext.translate('navigation.english', 'Englisch'),
			value: 'en',
			checked: l10nContext.language === 'en'
		}
	];

	const clientCanAccessCoreProducts = clientContext.permittedProductGroups().length > 0;



	const navigationItems = [
		{
			link: Route.DASHBOARD,
			icon: IconIdentifier.DASHBOARD,
			label: l10nContext.translate('navigation.dashboard', 'Dashboard'),
			isDisabled: !clientCanAccessCoreProducts
		},
		{
			link: Route.DEVICES,
			icon: IconIdentifier.DEVICE,
			label: l10nContext.translate('navigation.devices', 'Geräte'),
			isDisabled: !authContext.hasAnyPermission(permissionGroupDevice) || !clientCanAccessCoreProducts
		},
		{
			link: Route.CLEARANCES,
			icon: IconIdentifier.CLEARANCE_SEQUENCE,
			label: l10nContext.translate('navigation.clearenceSequences', 'Freimessungen'),
			// For product group 'generic sequences' the clearances should be disabled; so no use of !clientCanAccessCoreProducts
			isDisabled: !authContext.hasAnyPermission(permissionGroupDevice) ||
				(!clientContext.selectedClient().ProductGroupRadiologySubscribed
				&& !clientContext.selectedClient().ProductGroupNuclearMedicineSubscribed)
		},
		{
			link: Route.MAINTENANCE_LOG,
			icon: IconIdentifier.OPERATING_LOG,
			label: l10nContext.translate('navigation.maintenanceLog', 'Betriebsbuch-Einträge'),
			isDisabled: !authContext.hasAnyPermission(permissionGroupMaintenanceLog) || !clientCanAccessCoreProducts
		},
		{
			link: Route.DOCUMENTS,
			icon: IconIdentifier.DOC,
			label: l10nContext.translate('navigation.documents', 'Dokumente'),
			isDisabled: !authContext.hasAnyPermission(permissionGroupDocument) || !clientCanAccessCoreProducts
		},
		{
			link: Route.REPORTS,
			icon: IconIdentifier.REPORT,
			label: l10nContext.translate('navigation.reports', 'Berichte'),
			isDisabled: !authContext.hasAnyPermission(permissionGroupReport) || !clientCanAccessCoreProducts
		},
		{
			link: Route.NUCLIDE_MANAGEMENT,
			icon: IconIdentifier.NUCLIDE,
			label: l10nContext.translate('navigation.nuclideManagement', 'Nuklid Management'),
			isDisabled: (
				!authContext.hasAnyPermission(permissionGroupGenerator)
				&& !authContext.hasAnyPermission(permissionGroupCyclotronProduct)
			) || !clientContext.selectedClient().ProductGroupWasteManagementSubscribed
		},
		{
			link: Route.ACTORS,
			icon: IconIdentifier.USER,
			label: l10nContext.translate('navigation.users', 'Nutzer'),
			isDisabled: !authContext.hasAnyPermission(permissionGroupMembership)
		}
	];

	const navigationItem = navigationItems.map(item => {
		return (
			<NavigationElementPrimary
				key={item.label}
				icon={item.icon}
				label={item.label}
				isActive={pathname.includes(item.link)}
				isOpen={openState !== false}
				isDisabled={item.isDisabled}
				link={item.link}
			/>
		);
	});

	const handleNavClick = () => {
		setOpenState(!openState);
	};

	const navPrimaryFooter = openState === false ?
		<>
			<ButtonIcon
				icon={IconIdentifier.MAIL}
				weight={ButtonIconWeight.GHOST}
				shape={ButtonIconShape.ROUND}
				onClick={() => window.open('mailto:smartarchivo@rapp-iso.de')}
			/>
			<ButtonIcon
				icon={IconIdentifier.MENUE_OPEN}
				weight={ButtonIconWeight.GHOST}
				shape={ButtonIconShape.ROUND}
				onClick={handleNavClick}
			/>
			<div
				className="navigation-primary__nav-items--language-switch-closed"
				onClick={() => setOpenState(true)}
				role="button"
				tabIndex={0}
			>
				{l10nContext.translate('navigation.languageSwitchClosed', 'DE')}
			</div>
		</>
		:
		<>
			<ButtonGhost
				buttonText={l10nContext.translate('navigation.mini', 'Support')}
				iconPosition={IconPosition.LEFT}
				icon={IconIdentifier.MAIL}
				onClick={() => window.open('mailto:smartarchivo@rapp-iso.de')}
			/>
			<ButtonGhost
				buttonText={l10nContext.translate('navigation.minimizeNav', 'Navigation minimieren')}
				iconPosition={IconPosition.LEFT}
				icon={IconIdentifier.MENUE_CLOSE}
				onClick={handleNavClick}
			/>
			<Select
				options={localeOptions}
				onchange={choosedLanguage}
				openDirection={SelectOpenDirection.TOP}
			/>
		</>;

	return (
		<nav className={`navigation-primary ${openState === false ? 'navigation-primary--close' : 'navigation-primary--open'}`}>
			<Link to={Route.HOME}>
				<div className="navigation-primary__logo" />
			</Link>
			<div className="navigation-primary__nav-items">
				{navigationItem}
				{navPrimaryFooter}
			</div>
		</nav>
	);
};
