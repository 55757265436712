import { Optional } from 'services/device/domain/business/common/misc/Optional';
import { CyclotronProductIsotope } from './CyclotronProductIsotope';
import { GeneratorTypeIsotope } from './GeneratorTypeIsotope';
import { GeneratorEluateIsotope } from './GeneratorEluateIsotope';
import { NuclideIsotope } from './NuclideIsotope';
import { NuclideHalfLifePerYear } from './NuclideHalfLifePerYear';
import { NuclideHalfLifePerHour } from './NuclideHalfLifePerHour';
import { NuclideSpecificActivity } from './NuclideSpecificActivity';
import { NuclideSurfaceContamination } from './NuclideSurfaceContamination';

export class NuclideIsotopeDefinition {

	private readonly isotope: NuclideIsotope;

	private readonly labelKey: string;

	private readonly halfLifePerYear: number;

	private readonly halfLifePerHour: number;

	private readonly givenSpecificActivity: number;

	private readonly givenSurfaceContamination: number;

	constructor(
		isotope: NuclideIsotope,
		labelKey: string,
		halfLifePerYear: number,
		halfLifePerHour: number,
		givenSpecificActivity: number,
		givenSurfaceContamination: number) {
		this.isotope = isotope;
		this.labelKey = labelKey;
		this.halfLifePerYear = halfLifePerYear;
		this.halfLifePerHour = halfLifePerHour;
		this.givenSpecificActivity = givenSpecificActivity;
		this.givenSurfaceContamination = givenSurfaceContamination;
	}

	public getIsotope(): NuclideIsotope {
		return this.isotope;
	}

	public getLabelKey(): string {
		return this.labelKey;
	}

	public getHalfLifePerYear(): number {
		return this.halfLifePerYear;
	}

	public getHalfLifePerHour(): number {
		return this.halfLifePerHour;
	}

	public getGivenSpecificActivity(): number {
		return this.givenSpecificActivity;
	}

	public getGivenSurfaceContamination(): number {
		return this.givenSurfaceContamination;
	}

	public toString = (): string => {
		return this.labelKey.toString();
	};

}

export const CESIUM_137_CS: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.CESIUM_137_CS,
	'nuclides.isotopes.cesium',
	NuclideHalfLifePerYear.CESIUM_137_CS,
	NuclideHalfLifePerHour.CESIUM_137_CS,
	NuclideSpecificActivity.CESIUM_137_CS,
	NuclideSurfaceContamination.CESIUM_137_CS
);

export const COBALT_58_CO: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.COBALT_58_CO,
	'nuclides.isotopes.cobalt58',
	NuclideHalfLifePerYear.COBALT_58_CO,
	NuclideHalfLifePerHour.COBALT_58_CO,
	NuclideSpecificActivity.COBALT_58_CO,
	NuclideSurfaceContamination.COBALT_58_CO
);

export const ERBIUM_169_ER: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.ERBIUM_169_ER,
	'nuclides.isotopes.erbium',
	NuclideHalfLifePerYear.ERBIUM_169_ER,
	NuclideHalfLifePerHour.ERBIUM_169_ER,
	NuclideSpecificActivity.ERBIUM_169_ER,
	NuclideSurfaceContamination.ERBIUM_169_ER
);

export const FLUORINE_18_F: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.FLUORINE_18_F,
	'nuclides.isotopes.fluorine',
	NuclideHalfLifePerYear.FLUORINE_18_F,
	NuclideHalfLifePerHour.FLUORINE_18_F,
	NuclideSpecificActivity.FLUORINE_18_F,
	NuclideSurfaceContamination.FLUORINE_18_F
);

export const GALLIUM_68_GA: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	GeneratorEluateIsotope.GALLIUM_68_GA,
	'nuclides.isotopes.gallium68',
	NuclideHalfLifePerYear.GALLIUM_68_GA,
	NuclideHalfLifePerHour.GALLIUM_68_GA,
	NuclideSpecificActivity.GALLIUM_68_GA,
	NuclideSurfaceContamination.GALLIUM_68_GA
);

export const GERMANIUM_68_GE: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	GeneratorTypeIsotope.GERMANIUM_68_GE,
	'nuclides.isotopes.germanium',
	NuclideHalfLifePerYear.GERMANIUM_68_GE,
	NuclideHalfLifePerHour.GERMANIUM_68_GE,
	NuclideSpecificActivity.GERMANIUM_68_GE,
	NuclideSurfaceContamination.GERMANIUM_68_GE
);

export const INDIUM_111_IN: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.INDIUM_111_IN,
	'nuclides.isotopes.indium',
	NuclideHalfLifePerYear.INDIUM_111_IN,
	NuclideHalfLifePerHour.INDIUM_111_IN,
	NuclideSpecificActivity.INDIUM_111_IN,
	NuclideSurfaceContamination.INDIUM_111_IN
);

export const IODINE_123_I: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.IODINE_123_I,
	'nuclides.isotopes.iodine123',
	NuclideHalfLifePerYear.IODINE_123_I,
	NuclideHalfLifePerHour.IODINE_123_I,
	NuclideSpecificActivity.IODINE_123_I,
	NuclideSurfaceContamination.IODINE_123_I
);

export const IODINE_131_I: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.IODINE_131_I,
	'nuclides.isotopes.iodine131',
	NuclideHalfLifePerYear.IODINE_131_I,
	NuclideHalfLifePerHour.IODINE_131_I,
	NuclideSpecificActivity.IODINE_131_I,
	NuclideSurfaceContamination.IODINE_131_I
);

export const LUTETIUM_177_LU: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.LUTETIUM_177_LU,
	'nuclides.isotopes.lutetium',
	NuclideHalfLifePerYear.LUTETIUM_177_LU,
	NuclideHalfLifePerHour.LUTETIUM_177_LU,
	NuclideSpecificActivity.LUTETIUM_177_LU,
	NuclideSurfaceContamination.LUTETIUM_177_LU
);

export const MOLYBDENUM_99_MO: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	GeneratorTypeIsotope.MOLYBDENUM_99_MO,
	'nuclides.isotopes.molybdenum',
	NuclideHalfLifePerYear.MOLYBDENUM_99_MO,
	NuclideHalfLifePerHour.MOLYBDENUM_99_MO,
	NuclideSpecificActivity.MOLYBDENUM_99_MO,
	NuclideSurfaceContamination.MOLYBDENUM_99_MO
);

export const NITROGEN_13_N: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.NITROGEN_13_N,
	'nuclides.isotopes.nitrogen',
	NuclideHalfLifePerYear.NITROGEN_13_N,
	NuclideHalfLifePerHour.NITROGEN_13_N,
	NuclideSpecificActivity.NITROGEN_13_N,
	NuclideSurfaceContamination.NITROGEN_13_N
);

export const RHENIUM_186_RE: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.RHENIUM_186_RE,
	'nuclides.isotopes.rhenium186',
	NuclideHalfLifePerYear.RHENIUM_186_RE,
	NuclideHalfLifePerHour.RHENIUM_186_RE,
	NuclideSpecificActivity.RHENIUM_186_RE,
	NuclideSurfaceContamination.RHENIUM_186_RE
);

export const TECHNETIUM_99M_TC: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	GeneratorEluateIsotope.TECHNETIUM_99M_TC,
	'nuclides.isotopes.technetium',
	NuclideHalfLifePerYear.TECHNETIUM_99M_TC,
	NuclideHalfLifePerHour.TECHNETIUM_99M_TC,
	NuclideSpecificActivity.TECHNETIUM_99M_TC,
	NuclideSurfaceContamination.TECHNETIUM_99M_TC
);

export const THALLIUM_201_TL: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.THALLIUM_201_TL,
	'nuclides.isotopes.thallium',
	NuclideHalfLifePerYear.THALLIUM_201_TL,
	NuclideHalfLifePerHour.THALLIUM_201_TL,
	NuclideSpecificActivity.THALLIUM_201_TL,
	NuclideSurfaceContamination.THALLIUM_201_TL
);

export const YTTRIUM_90_Y: NuclideIsotopeDefinition = new NuclideIsotopeDefinition(
	CyclotronProductIsotope.YTTRIUM_90_Y,
	'nuclides.isotopes.yttrium',
	NuclideHalfLifePerYear.YTTRIUM_90_Y,
	NuclideHalfLifePerHour.YTTRIUM_90_Y,
	NuclideSpecificActivity.YTTRIUM_90_Y,
	NuclideSurfaceContamination.YTTRIUM_90_Y
);


/**
 *
 * All nuclides
 *
 */

export function getAllNuclideIsotopeDefinitions(): ReadonlyArray<NuclideIsotopeDefinition> {
	return [
		CESIUM_137_CS,
		COBALT_58_CO,
		ERBIUM_169_ER,
		FLUORINE_18_F,
		GALLIUM_68_GA,
		GERMANIUM_68_GE,
		INDIUM_111_IN,
		IODINE_123_I,
		IODINE_131_I,
		LUTETIUM_177_LU,
		MOLYBDENUM_99_MO,
		NITROGEN_13_N,
		RHENIUM_186_RE,
		TECHNETIUM_99M_TC,
		THALLIUM_201_TL,
		YTTRIUM_90_Y
	];
}

export function getNuclideIsotopeDefinition(nuclideIsotope: NuclideIsotope | string): NuclideIsotopeDefinition {
	const nuclideIsotopeDefinition: Optional<NuclideIsotopeDefinition> =
		getAllNuclideIsotopeDefinitions().find((definition) => definition.getIsotope() === nuclideIsotope) ?? null;
	if (nuclideIsotopeDefinition === null) {
		throw new Error('Missing nuclide isotope definition: ' + nuclideIsotope);
	}
	return nuclideIsotopeDefinition;
}

/**
 *
 * Generator type nuclides
 *
 */

export function getGeneratorTypeIsotopeDefinitions(): ReadonlyArray<NuclideIsotopeDefinition> {
	return [
		GERMANIUM_68_GE,
		MOLYBDENUM_99_MO
	];
}

export function getGeneratorTypeIsotopeDefinition(generatorIsotope: NuclideIsotope | string): NuclideIsotopeDefinition {
	const generatorIsotopeDefinition: Optional<NuclideIsotopeDefinition> =
		getGeneratorTypeIsotopeDefinitions().find((definition) => definition.getIsotope() === generatorIsotope) ?? null;
	if (generatorIsotopeDefinition === null) {
		throw new Error('Missing generator isotope definition: ' + generatorIsotope);
	}
	return generatorIsotopeDefinition;
}


/**
 *
 * Generator nuclides
 *
 */

export function getGeneratorEluateIsotopeDefinitions(): ReadonlyArray<NuclideIsotopeDefinition> {
	return [
		GALLIUM_68_GA,
		TECHNETIUM_99M_TC
	];
}

export function getGeneratorEluateIsotopeDefinition(generatorIsotope: NuclideIsotope | string): NuclideIsotopeDefinition {
	const generatorIsotopeDefinition: Optional<NuclideIsotopeDefinition> =
		getGeneratorEluateIsotopeDefinitions().find((definition) => definition.getIsotope() === generatorIsotope) ?? null;
	if (generatorIsotopeDefinition === null) {
		throw new Error('Missing generator isotope definition: ' + generatorIsotope);
	}
	return generatorIsotopeDefinition;
}

/**
 *
 * Cyclotron product nuclides
 *
 */

export function getCyclotronProductIsotopeDefinitions(): ReadonlyArray<NuclideIsotopeDefinition> {
	return [
		CESIUM_137_CS,
		COBALT_58_CO,
		ERBIUM_169_ER,
		FLUORINE_18_F,
		INDIUM_111_IN,
		IODINE_123_I,
		IODINE_131_I,
		LUTETIUM_177_LU,
		NITROGEN_13_N,
		RHENIUM_186_RE,
		THALLIUM_201_TL,
		YTTRIUM_90_Y
	];
}

export function getCyclotronProductIsotopeDefinition(cyclotronProductIsotope: NuclideIsotope | string): NuclideIsotopeDefinition {
	const cyclotronProductIsotopeDefinition: Optional<NuclideIsotopeDefinition> =
	getCyclotronProductIsotopeDefinitions().find((definition) => definition.getIsotope() === cyclotronProductIsotope) ?? null;
	if (cyclotronProductIsotopeDefinition === null) {
		throw new Error('Missing cyclotron product isotope definition: ' + cyclotronProductIsotope);
	}
	return cyclotronProductIsotopeDefinition;
}

// Calculation current activity

export function calculateCurrentActivity(calibrationDatetime: Date, nominalActivity: number, isotope: NuclideIsotope ): number {

	const currentDateTime = new Date();
	const timeDifference = currentDateTime.getTime() - calibrationDatetime.getTime();
	const timeDifferenceInHours = timeDifference / 1000 / 60 / 60;
	const halfLifePerHour = getNuclideIsotopeDefinition(isotope).getHalfLifePerHour();

	const currentActivity =
		nominalActivity * Math.pow(0.5, timeDifferenceInHours / halfLifePerHour);

	return currentActivity;
}

export function calculateRemainingTime(halflife: number, currentSpecificActivity: number, specificActivity: number): number {
	const t = halflife * (Math.log(specificActivity / currentSpecificActivity) / Math.log(1 / 2));
	return t;
}
