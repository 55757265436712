import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NetworkContext } from 'context/NetworkContext';

import { ClientContext } from 'services/core/context/ClientContext';
import { ClientModel } from 'services/core/lib/auth/AuthService';
import { AuthServiceBuilder } from 'services/core/lib/auth/AuthServiceBuilder';
import { ClientSelectView } from 'services/core/presentation/view/ClientSelectView';
import { ProductGroup } from 'services/device/domain/business/inventory/ProductGroup';
import { resetState as resetDeviceState } from 'services/device/store/devicesSlice';
import { resetState as resetRecordState } from 'services/device/store/recordSlice';
import { resetState as resetSequenceState } from 'services/device/store/sequenceSlice';
import { resetState as resetDocumentState } from 'services/documents/store/documentSlice';
import { resetState as resetFolderState } from 'services/documents/store/folderSlice';
import { resetState as resetMaintenanceLogEntryState } from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { resetState as resetRoleState } from 'services/core/store/roleSlice';
import { resetState as resetMembershipState } from 'services/core/store/membershipSlice';
import { resetState as resetReportState } from 'services/report/store/reportSlice';
import { resetState as resetGeneratorState } from 'services/nuclide/store/generatorSlice';
import { resetState as resetEluateState } from 'services/nuclide/store/eluateSlice';
import { AuthContext } from './AuthContext';

export const ClientContextProvider = (props: any): JSX.Element => {

	const networkContext = useContext(NetworkContext);
	const authService = AuthServiceBuilder.build(networkContext.online);
	const auth = useContext(AuthContext);

	const dispatch = useDispatch();

	const [selectedClientUuid, setSelectedClientUuid] = useState(authService.getSelectedClientModel()?.Uuid ?? null);

	const select = (clientUuid: string): void => {
		if (clientUuid === selectedClientUuid) {
			return;
		}
		const clientModel = authService.getAvailableClientModelByUuid(clientUuid);
		authService.setSelectedClientModel(clientModel);
		setSelectedClientUuid(clientUuid);
	};

	const deselect = (): void => {
		authService.setSelectedClientModel(null);
		setSelectedClientUuid(null);
	};

	const selectedClient = (): ClientModel | null => {
		return authService.getSelectedClientModel();
	};

	const availableClient = (clientUuid: string): ClientModel | null => {
		return authService.getAvailableClientModelByUuid(clientUuid);
	};

	const availableClients = (): Array<ClientModel> => {
		const availableClientModelCollection = authService.getAvailableClientModelCollection();
		if (availableClientModelCollection.length === 0) {
			void auth.invalidate();
		}
		return availableClientModelCollection;
	};

	const permittedProductGroups = (): Array<ProductGroup> => {
		const productGroups: Array<ProductGroup> = [];
		if (selectedClient()?.ProductGroupNuclearMedicineSubscribed) {
			productGroups.push(ProductGroup.NUCLEAR_MEDICINE);
		}
		if (selectedClient()?.ProductGroupRadiologySubscribed) {
			productGroups.push(ProductGroup.RADIOLOGY);
		}
		if (selectedClient()?.ProductGroupGenericSequencesSubscribed) {
			productGroups.push(ProductGroup.GENERIC_SEQUENCES);
		}
		return productGroups;
	};

	if (selectedClientUuid === null) {
		dispatch(resetDeviceState());
		dispatch(resetSequenceState());
		dispatch(resetRecordState());
		dispatch(resetMaintenanceLogEntryState());
		dispatch(resetDocumentState());
		dispatch(resetFolderState());
		dispatch(resetRoleState());
		dispatch(resetMembershipState());
		dispatch(resetReportState());
		dispatch(resetGeneratorState());
		dispatch(resetEluateState());
	}

	if (availableClients().length === 1) {
		select(availableClients()[0].Uuid);
	}

	const clientInterceptionView = (
		<ClientSelectView />
	);

	return (
		<ClientContext.Provider
			value={{
				selectedClientUuid,
				select,
				deselect,
				selectedClient,
				availableClient,
				availableClients,
				permittedProductGroups
			}}
		>
			<>
				{selectedClientUuid !== null ? props.children : clientInterceptionView}
			</>
		</ClientContext.Provider>
	);

};
