import { PrivateLocalStorage } from 'lib/browser-storage/PrivateLocalStorage';
import { PrivateLocalStorageKey } from 'lib/browser-storage/PrivateLocalStorageKey';
import { PublicLocalStorage } from 'lib/browser-storage/PublicLocalStorage';
import { PublicLocalStorageKey } from 'lib/browser-storage/PublicLocalStorageKey';

import { ApiConnector } from 'services/core/lib/auth/api-connector/ApiConnector';

export interface JsonWebToken {
	readonly Token: string;
	readonly ValidUntil: number;
}

export interface ActorModel {
	readonly Uuid: string;
	readonly Username: string | null;
	readonly MailAddress: string | null;
	readonly Realname: string;
}

export interface ClientModel {
	readonly Uuid: string;
	readonly Name: string;
	readonly ProductGroupNuclearMedicineSubscribed: boolean;
	readonly ProductGroupRadiologySubscribed: boolean;
	readonly ProductGroupWasteManagementSubscribed: boolean;
	readonly ProductGroupGenericSequencesSubscribed: boolean;


	readonly Facilities: Array<FacilityModel>;
}

export interface FacilityModel {
	readonly Uuid: string;
	readonly Name: string;
	readonly RadiationFactor?: number;
	readonly Permissions: Array<string>;
}

export enum Permission {
	ACTOR_VIEW = 'ACTOR_VIEW',
	ACTOR_CREATE = 'ACTOR_CREATE',
	ACTOR_UPDATE = 'ACTOR_UPDATE',
	ACTOR_UPDATEPERMISSIONS = 'ACTOR_UPDATEPERMISSIONS',
	ACTOR_ACTIVATE = 'ACTOR_ACTIVATE',
	ACTOR_LOCK = 'ACTOR_LOCK',
	ACTOR_UNLOCK = 'ACTOR_UNLOCK',
	ACTOR_DELETE = 'ACTOR_DELETE',
	MEMBERSHIP_VIEW = 'MEMBERSHIP_VIEW',
	MEMBERSHIP_CREATE = 'MEMBERSHIP_CREATE',
	MEMBERSHIP_UPDATE = 'MEMBERSHIP_UPDATE',
	MEMBERSHIP_DELETE = 'MEMBERSHIP_DELETE',
	EVENT_STORE_VIEW = 'EVENT_STORE_VIEW',
	DEVICE_VIEW = 'DEVICE_VIEW',
	DEVICE_CREATE = 'DEVICE_CREATE',
	DEVICE_UPDATE = 'DEVICE_UPDATE',
	DEVICE_UPDATE_STATE = 'DEVICE_UPDATESTATE',
	DEVICE_DELETE = 'DEVICE_DELETE',
	SEQUENCE_VIEW = 'SEQUENCE_VIEW',
	SEQUENCE_CREATE = 'SEQUENCE_CREATE',
	SEQUENCE_UPDATE = 'SEQUENCE_UPDATE',
	SEQUENCE_UPDATE_STATE = 'SEQUENCE_UPDATESTATE',
	SEQUENCE_UPDATE_ADMIN = 'SEQUENCE_UPDATEADMIN',
	SEQUENCE_DELETE = 'SEQUENCE_DELETE',
	RECORD_VIEW = 'RECORD_VIEW',
	RECORD_CREATE = 'RECORD_CREATE',
	RECORD_SUPPLEMENT = 'RECORD_SUPPLEMENT',
	RECORD_UPDATE = 'RECORD_UPDATE',
	RECORD_UPDATE_ADD_DOCUMENT = 'RECORD_ADDDOCUMENT',
	RECORD_DELETE = 'RECORD_DELETE',
	MAINTENANCE_LOG_ENTRY_VIEW = 'MAINTENANCE_LOG_ENTRY_VIEW',
	MAINTENANCE_LOG_ENTRY_CREATE = 'MAINTENANCE_LOG_ENTRY_CREATE',
	MAINTENANCE_LOG_ENTRY_UPDATE = 'MAINTENANCE_LOG_ENTRY_UPDATE',
	MAINTENANCE_LOG_ENTRY_DELETE = 'MAINTENANCE_LOG_ENTRY_DELETE',
	DOCUMENT_FOLDER_VIEW = 'DOCUMENT_FOLDER_VIEW',
	DOCUMENT_FOLDER_CREATE = 'DOCUMENT_FOLDER_CREATE',
	DOCUMENT_FOLDER_UPDATE = 'DOCUMENT_FOLDER_UPDATE',
	DOCUMENT_FOLDER_UPDATE_STATE = 'DOCUMENT_FOLDER_UPDATESTATE',
	DOCUMENT_FOLDER_DELETE = 'DOCUMENT_FOLDER_DELETE',
	DOCUMENT_VIEW = 'DOCUMENT_VIEW',
	DOCUMENT_CREATE = 'DOCUMENT_CREATE',
	DOCUMENT_UPDATE = 'DOCUMENT_UPDATE',
	DOCUMENT_UPDATE_STATE = 'DOCUMENT_UPDATESTATE',
	DOCUMENT_DELETE = 'DOCUMENT_DELETE',
	REPORT_VIEW = 'REPORT_VIEW',
	REPORT_CREATE = 'REPORT_CREATE',
	REPORT_UPDATE = 'REPORT_UPDATE',
	REPORT_DELETE = 'REPORT_DELETE',
	GENERATOR_VIEW = 'GENERATOR_VIEW',
	GENERATOR_CREATE = 'GENERATOR_CREATE',
	GENERATOR_UPDATE = 'GENERATOR_UPDATE',
	GENERATOR_DELETE = 'GENERATOR_DELETE',
	GENERATOR_ELUAT_VIEW = 'GENERATOR_ELUAT_VIEW',
	GENERATOR_ELUAT_CREATE = 'GENERATOR_ELUAT_CREATE',
	GENERATOR_ELUAT_UPDATE = 'GENERATOR_ELUAT_UPDATE',
	GENERATOR_ELUAT_SUPPLEMENT = 'GENERATOR_ELUAT_SUPPLEMENT',
	GENERATOR_ELUAT_DELETE = 'GENERATOR_ELUAT_DELETE',
	GENERATOR_ELUAT_REACTIVATE = 'GENERATOR_ELUAT_REACTIVATE',
	CYCLOTRON_PRODUCT_VIEW = 'CYCLOTRON_PRODUCT_VIEW',
	CYCLOTRON_PRODUCT_CREATE = 'CYCLOTRON_PRODUCT_CREATE',
	CYCLOTRON_PRODUCT_UPDATE = 'CYCLOTRON_PRODUCT_UPDATE',
	CYCLOTRON_PRODUCT_DELETE = 'CYCLOTRON_PRODUCT_DELETE',
	CYCLOTRON_PRODUCT_REACTIVATE = 'CYCLOTRON_PRODUCT_REACTIVATE',
	WASTE_MANAGEMENT_VIEW = 'WASTE_MANAGEMENT_VIEW',
	WASTE_MANAGEMENT_CREATE = 'WASTE_MANAGEMENT_CREATE',
	WASTE_MANAGEMENT_UPDATE = 'WASTE_MANAGEMENT_UPDATE',
	WASTE_MANAGEMENT_DELETE = 'WASTE_MANAGEMENT_DELETE',
	CONTAINER_DISPATCH_VIEW = 'CONTAINER_DISPATCH_VIEW',
	CONTAINER_DISPATCH_CREATE = 'CONTAINER_DISPATCH_CREATE',
	CONTAINER_DISPATCH_DELETE = 'CONTAINER_DISPATCH_DELETE',
	CONTAINER_DISPOSE_VIEW = 'CONTAINER_DISPOSE_VIEW',
	CONTAINER_DISPOSE_CREATE = 'CONTAINER_DISPOSE_CREATE',
	CONTAINER_DISPOSE_DELETE = 'CONTAINER_DISPOSE_DELETE',
	WASTE_MANAGEMENT_REPORT_VIEW = 'WASTE_MANAGEMENT_REPORT_VIEW',
	WASTE_MANAGEMENT_REPORT_CREATE = 'WASTE_MANAGEMENT_REPORT_CREATE',
	WASTE_MANAGEMENT_REPORT_UPDATE = 'WASTE_MANAGEMENT_REPORT_UPDATE',
	WASTE_MANAGEMENT_REPORT_DELETE = 'WASTE_MANAGEMENT_REPORT_DELETE'
}

export const permissionGroupDevice: Array<Permission> = [
	Permission.DEVICE_VIEW,
	Permission.DEVICE_CREATE,
	Permission.DEVICE_UPDATE,
	Permission.DEVICE_UPDATE_STATE,
	Permission.DEVICE_DELETE
];

export const permissionGroupSequence: Array<Permission> = [
	Permission.SEQUENCE_VIEW,
	Permission.SEQUENCE_CREATE,
	Permission.SEQUENCE_UPDATE,
	Permission.SEQUENCE_UPDATE_STATE,
	Permission.SEQUENCE_UPDATE_ADMIN,
	Permission.SEQUENCE_DELETE
];

export const permissionGroupMaintenanceLog: Array<Permission> = [
	Permission.MAINTENANCE_LOG_ENTRY_VIEW,
	Permission.MAINTENANCE_LOG_ENTRY_CREATE,
	Permission.MAINTENANCE_LOG_ENTRY_UPDATE,
	Permission.MAINTENANCE_LOG_ENTRY_DELETE
];

export const permissionGroupDocument: Array<Permission> = [
	Permission.DOCUMENT_FOLDER_VIEW,
	Permission.DOCUMENT_FOLDER_CREATE,
	Permission.DOCUMENT_FOLDER_UPDATE,
	Permission.DOCUMENT_FOLDER_UPDATE_STATE,
	Permission.DOCUMENT_FOLDER_DELETE,
	Permission.DOCUMENT_CREATE,
	Permission.DOCUMENT_UPDATE,
	Permission.DOCUMENT_UPDATE_STATE,
	Permission.DOCUMENT_DELETE
];

export const permissionGroupMembership: Array<Permission> = [
	Permission.MEMBERSHIP_VIEW,
	Permission.MEMBERSHIP_CREATE,
	Permission.MEMBERSHIP_UPDATE,
	Permission.MEMBERSHIP_DELETE
];

export const permissionGroupReport: Array<Permission> = [
	Permission.REPORT_VIEW,
	Permission.REPORT_CREATE,
	Permission.REPORT_UPDATE,
	Permission.REPORT_DELETE
];

export const permissionGroupGenerator: Array<Permission> = [
	Permission.GENERATOR_VIEW,
	Permission.GENERATOR_CREATE,
	Permission.GENERATOR_UPDATE,
	Permission.GENERATOR_DELETE,
	Permission.GENERATOR_ELUAT_CREATE,
	Permission.GENERATOR_ELUAT_UPDATE,
	Permission.GENERATOR_ELUAT_SUPPLEMENT,
	Permission.GENERATOR_ELUAT_DELETE,
	Permission.GENERATOR_ELUAT_REACTIVATE
];

export const permissionGroupCyclotronProduct: Array<Permission> = [
	Permission.CYCLOTRON_PRODUCT_VIEW,
	Permission.CYCLOTRON_PRODUCT_CREATE,
	Permission.CYCLOTRON_PRODUCT_UPDATE,
	Permission.CYCLOTRON_PRODUCT_DELETE,
	Permission.CYCLOTRON_PRODUCT_REACTIVATE
];

export const permissionGroupWasteManagement: Array<Permission> = [
	Permission.WASTE_MANAGEMENT_VIEW,
	Permission.WASTE_MANAGEMENT_CREATE,
	Permission.WASTE_MANAGEMENT_UPDATE,
	Permission.WASTE_MANAGEMENT_DELETE,
	Permission.CONTAINER_DISPATCH_VIEW,
	Permission.CONTAINER_DISPATCH_CREATE,
	Permission.CONTAINER_DISPATCH_DELETE,
	Permission.CONTAINER_DISPOSE_VIEW,
	Permission.CONTAINER_DISPOSE_CREATE,
	Permission.CONTAINER_DISPOSE_DELETE
];

export const permissionGroupContainerDispatch: Array<Permission> = [
	Permission.CONTAINER_DISPATCH_VIEW,
	Permission.CONTAINER_DISPATCH_CREATE,
	Permission.CONTAINER_DISPATCH_DELETE
];

export const permissionGroupContainerDispose: Array<Permission> = [
	Permission.CONTAINER_DISPOSE_VIEW,
	Permission.CONTAINER_DISPOSE_CREATE,
	Permission.CONTAINER_DISPOSE_DELETE
];

export const permissionGroupWasteManagementReport: Array<Permission> = [
	Permission.WASTE_MANAGEMENT_REPORT_VIEW,
	Permission.WASTE_MANAGEMENT_REPORT_CREATE,
	Permission.WASTE_MANAGEMENT_REPORT_UPDATE,
	Permission.WASTE_MANAGEMENT_REPORT_DELETE
];

export class AuthService {

	private apiConnector: ApiConnector;

	private actorModel?: ActorModel = null;
	private availableClientModelCollection: Array<ClientModel> = [];
	private selectedClientModel?: ClientModel = null;
	private selectedFacilityModel?: FacilityModel = null;
	private jsonWebToken?: JsonWebToken = null;

	public constructor(apiConnector: ApiConnector) {
		this.apiConnector = apiConnector;
	}

	public getLatestActorUser(): string | null {
		return this.getLatestActorUsers()[0] ?? null;
	}

	public getLatestActorUsers(): Array<string> {
		return PublicLocalStorage.get().read<Array<string>>(PublicLocalStorageKey.LATEST_ACTOR_USERS) ?? [];
	}

	public hasActor(): boolean {
		return this.getActor() !== null;
	}

	public getActor(): ActorModel | null {
		if (this.actorModel === null) {
			this.actorModel = PublicLocalStorage.get().read<ActorModel>(PublicLocalStorageKey.ACTOR_MODEL);
		}
		return this.actorModel;
	}

	public getAvailableClientModelCollection(): Array<ClientModel> {
		if (this.availableClientModelCollection.length === 0) {
			const availableClientModelCollection = PrivateLocalStorage.get(this.getActor()?.Uuid).read<Array<ClientModel>>(
				PrivateLocalStorageKey.CLIENT_MODEL_COLLECTION
			);
			this.availableClientModelCollection = availableClientModelCollection ?? [];
		}
		return this.availableClientModelCollection;
	}

	public getAvailableClientModelByUuid(uuid: string): ClientModel | null {
		for (const clientModel of this.availableClientModelCollection) {
			if (clientModel.Uuid === uuid) {
				return clientModel;
			}
		}
		return null;
	}

	public getSelectedClientModel(): ClientModel | null {
		if (this.selectedClientModel === null) {
			this.selectedClientModel = PrivateLocalStorage.get(this.getActor()?.Uuid)
				.read<ClientModel>(PrivateLocalStorageKey.SELECTED_CLIENT_MODEL);
		}
		return this.selectedClientModel;
	}

	public setSelectedClientModel(clientModel?: ClientModel): void {
		this.selectedClientModel = clientModel;
		if (this.selectedClientModel === null) {
			PrivateLocalStorage.get(this.getActor()?.Uuid).remove(PrivateLocalStorageKey.SELECTED_CLIENT_MODEL);
		} else {
			PrivateLocalStorage.get(this.getActor()?.Uuid)
				.write<ClientModel>(PrivateLocalStorageKey.SELECTED_CLIENT_MODEL, this.selectedClientModel);
		}
		this.setSelectedFacilityModel(null);
	}

	public getAvailableFacilityModelByUuid(uuid: string): FacilityModel | null {
		const selectedClientModel = this.getSelectedClientModel();
		if (selectedClientModel === null) {
			return null;
		}
		for (const facilityModel of selectedClientModel.Facilities) {
			if (facilityModel.Uuid === uuid) {
				return facilityModel;
			}
		}
		return null;
	}

	public getSelectedFacilityModel(): FacilityModel | null {
		if (this.selectedFacilityModel === null) {
			this.selectedFacilityModel = PrivateLocalStorage.get(this.getActor()?.Uuid)
				.read<FacilityModel>(PrivateLocalStorageKey.SELECTED_FACILITY_MODEL);
		}
		return this.selectedFacilityModel;
	}

	public setSelectedFacilityModel(facilityModel?: FacilityModel): void {
		this.selectedFacilityModel = facilityModel;
		if (this.selectedFacilityModel === null) {
			PrivateLocalStorage.get(this.getActor()?.Uuid).remove(PrivateLocalStorageKey.SELECTED_FACILITY_MODEL);
		} else {
			PrivateLocalStorage.get(this.getActor()?.Uuid)
				.write<FacilityModel>(PrivateLocalStorageKey.SELECTED_FACILITY_MODEL, this.selectedFacilityModel);
		}
	}

	public hasPermission(permission: Permission): boolean {
		if (!this.hasActor() || this.selectedClientModel === null || this.selectedFacilityModel === null) {
			return false;
		}
		return this.selectedFacilityModel.Permissions.includes(permission.toString());
	}

	public hasAnyPermission(permissions: Array<Permission>): boolean {
		for (const permission of permissions) {
			if (this.hasPermission(permission)) {
				return true;
			}
		}
		return false;
	}

	public hasAllPermissions(permissions: Array<Permission>): boolean {
		for (const permission of permissions) {
			if (!this.hasPermission(permission)) {
				return false;
			}
		}
		return true;
	}

	public getJsonWebToken(): JsonWebToken | null {
		if (this.jsonWebToken === null) {
			this.jsonWebToken = PublicLocalStorage.get().read<JsonWebToken>(PublicLocalStorageKey.JSON_WEB_TOKEN);
			if (this.jsonWebToken === null || this.jsonWebToken.ValidUntil < Date.now()) {
				return null;
			}
		}
		return this.jsonWebToken;
	}

	public async authenticate(user: string, password: string): Promise<ActorModel | null> {
		try {
			const apiConnectorResult = await this.apiConnector.authenticate(user, password);
			this.setActor(apiConnectorResult.ActorModel);
			this.setAvailableClientModelCollection(apiConnectorResult.AvailableClientModelCollection);
			this.setSelectedClientModel(null);
			this.setSelectedFacilityModel(null);
			this.setJsonWebToken(apiConnectorResult.JsonWebToken);
		} catch (e) {
			this.setActor(null);
			this.setAvailableClientModelCollection([]);
			this.setSelectedClientModel(null);
			this.setSelectedFacilityModel(null);
			this.setJsonWebToken(null);
			throw e;
		}
		return this.getActor();
	}

	public async unauthenticate(): Promise<void> {
		try {
			await this.apiConnector.unauthenticate(this.getJsonWebToken());
		} catch (e) {// eslint-disable-line no-empty
		}

		this.setActor(null);
		this.setAvailableClientModelCollection([]);
		this.setSelectedClientModel(null);
		this.setSelectedFacilityModel(null);
		this.setJsonWebToken(null);
	}

	public async test(): Promise<boolean> {
		try {
			// eslint-disable-next-line @typescript-eslint/return-await
			return this.apiConnector.test(this.getJsonWebToken());
		} catch (e) {
			return false;
		}
	}

	public async require(): Promise<void> {
		await this.apiConnector.test(this.getJsonWebToken());
	}

	private setActor(actorModel?: ActorModel): void {
		this.actorModel = actorModel ?? null;
		if (this.actorModel === null) {
			PublicLocalStorage.get().remove(PublicLocalStorageKey.ACTOR_MODEL);
		} else {
			PublicLocalStorage.get().write<ActorModel>(PublicLocalStorageKey.ACTOR_MODEL, this.actorModel);
		}
	}

	private setAvailableClientModelCollection(availableClientModelCollection: Array<ClientModel>): void {
		this.availableClientModelCollection = availableClientModelCollection;
		if (this.availableClientModelCollection.length === 0) {
			PrivateLocalStorage.get(this.getActor()?.Uuid).remove(PrivateLocalStorageKey.CLIENT_MODEL_COLLECTION);
		} else {
			PrivateLocalStorage.get(this.getActor()?.Uuid).write<Array<ClientModel>>(
				PrivateLocalStorageKey.CLIENT_MODEL_COLLECTION, this.availableClientModelCollection
			);
		}
	}

	private setJsonWebToken(jsonWebToken?: JsonWebToken): void {
		this.jsonWebToken = jsonWebToken ?? null;
		if (this.jsonWebToken === null) {
			PublicLocalStorage.get().remove(PublicLocalStorageKey.JSON_WEB_TOKEN);
		} else {
			PublicLocalStorage.get().write<JsonWebToken>(PublicLocalStorageKey.JSON_WEB_TOKEN, this.jsonWebToken);
		}
	}

}
