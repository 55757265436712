import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Route } from 'router/Route';

import { L10nContext } from 'context/L10nContext';

import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Valuation } from 'services/device/domain/business/common/Valuation';
import { getSequenceDue, getSequenceValuation } from 'services/device/domain/business/util/SequenceUtil';
import { DeviceViewModel } from 'services/device/domain/model/DeviceModel';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import {
	countDevicesWithOpenMaintenanceLogEntries,
	countDueDevices,
	countNoticableDevices
} from 'services/device/lib/dashboard/DeviceDashboardUtil';
import { selectFilteredDevices } from 'services/device/store/devicesSlice';
import { selectActiveSequencesByDevices } from 'services/device/store/sequenceSlice';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import { selectOpenMaintenanceLogEntriesByDevices } from 'services/maintenance-log/store/maintenanceLogEntrySlice';

import { ButtonToggleSet } from 'presentation/ui/components/button/ButtonToggleSet';
import { CardEmpty } from 'presentation/ui/components/cards/card-empty/CardEmpty';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { CardDeviceWithMaintenanceLogEntries } from 'presentation/ui/compositions/card-device-dashboard/CardDeviceWithMaintenanceLogEntries';
import { CardDeviceWithSequences } from 'presentation/ui/compositions/card-device-dashboard/CardDeviceWithSequences';
import { Expandable } from 'presentation/ui/compositions/expandable/Expandable';
import { ExpandableBody } from 'presentation/ui/compositions/expandable/expandable-body/ExpandableBody';
import { ExpandableHeader } from 'presentation/ui/compositions/expandable/expandable-header/ExpandableHeader';
import { CardCollectionLayout } from 'presentation/ui/layouts/card-collection-layout/CardCollectionLayout';
import { TextLink } from 'presentation/ui/partials/text-link/TextLink';
import { ButtonToggleProps } from 'presentation/ui/partials/button/button-toggle/ButtonToggle';

interface DeviceCollectionProps {
	noticableDeviceCount: number;
	onAddSequenceRecord: (sequence: SequenceViewModel) => void;
	onEditMaintenanceLogEntry: (maintenanceLogEntry: MaintenanceLogEntryViewModel) => void;
}

enum DeviceFilter {
	DUE = 'DUE',
	VALUATION = 'VALUATION',
	MAINTENANCE_LOG_OPEN = 'MAINTENANCE_LOG_OPEN'
}

enum ObjectiveType {
	SEQUENCE = 'SEQUENCE',
	MAINTENANCE_LOG_ENTRY = 'MAINTENANCE_LOG_ENTRY'
}

type DeviceWithObjectives = {
	device: DeviceViewModel,
	objectives: Array<SequenceViewModel | MaintenanceLogEntryViewModel>,
	objectiveType: ObjectiveType
};
type EffectiveDevicesWithObjectives = Array<DeviceWithObjectives>;

export const DeviceCollection = (props: DeviceCollectionProps): JSX.Element => {
	const { noticableDeviceCount, onAddSequenceRecord, onEditMaintenanceLogEntry } = props;

	// Consume the contexts
	const facilityContext = useContext(FacilityContext);
	const clientContext = useContext(ClientContext);
	const l10nContext = useContext(L10nContext);

	// Read the devices from the state store
	const devices = useSelector(selectFilteredDevices(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		true,
		false
	));

	const sequencesMap = useSelector(selectActiveSequencesByDevices(devices));
	const maintenanceLogEntryMap = useSelector(selectOpenMaintenanceLogEntriesByDevices(devices));

	const [deviceFilter, setDeviceFilter] = useState<DeviceFilter>(DeviceFilter.DUE);
	const [dueDeviceCount, setDueDeviceCount] = useState<number>(null);
	const [noticableDeviceCollectionCount, setNoticableDeviceCollectionCount] = useState<number>(null);
	const [maintenanceLogEntryDeviceCount, setMaintenanceLogEntryDeviceCount] = useState<number>(null);
	const [effectiveDevices, setEffectiveDevices] = useState<EffectiveDevicesWithObjectives>(null);

	useEffect(() => {
		setDueDeviceCount(countDueDevices(devices, sequencesMap));
		setNoticableDeviceCollectionCount(countNoticableDevices(devices, sequencesMap));
		setMaintenanceLogEntryDeviceCount(countDevicesWithOpenMaintenanceLogEntries(devices, maintenanceLogEntryMap));

	}, [devices, maintenanceLogEntryMap, sequencesMap, noticableDeviceCount]);

	const effective: EffectiveDevicesWithObjectives = [];

	function filterDeviceCollectionCounts() {
		const filter = async (): Promise<void> => {
			if (deviceFilter === DeviceFilter.VALUATION) {
				for (const device of devices) {
					if (sequencesMap?.has(device.Uuid)) {
						const objective: DeviceWithObjectives = {
							device,
							objectives: [],
							objectiveType: ObjectiveType.SEQUENCE
						};
						for (const sequence of sequencesMap?.get(device.Uuid)) {
							const valuation = getSequenceValuation(sequence);
							if (valuation === Valuation.NOTICEABLE || valuation === Valuation.UNACCEPTABLE) {
								objective.objectives.push(sequence);
							}
						}
						if (objective.objectives.length > 0) {
							effective.push(objective);
						}
					}
				}
			} else if (deviceFilter === DeviceFilter.DUE) {
				for (const device of devices) {
					if (sequencesMap?.has(device.Uuid)) {

						const objective: DeviceWithObjectives = {
							device,
							objectives: [],
							objectiveType: ObjectiveType.SEQUENCE
						};
						for (const sequence of sequencesMap?.get(device.Uuid)) {
							// eslint-disable-next-line no-await-in-loop
							if (getSequenceDue(sequence)) {
								objective.objectives.push(sequence);
							}
						}
						if (objective.objectives.length > 0) {
							effective.push(objective);
						}
					}
				}
			} else if (deviceFilter === DeviceFilter.MAINTENANCE_LOG_OPEN) {
				for (const device of devices) {
					if (maintenanceLogEntryMap?.has(device.Uuid)) {
						effective.push({
							device,
							objectives: maintenanceLogEntryMap?.get(device.Uuid),
							objectiveType: ObjectiveType.MAINTENANCE_LOG_ENTRY
						});
					}
				}
			}
			setEffectiveDevices(effective);
		};
		return filter;
	}

	useEffect(
		() => {
			const filter = filterDeviceCollectionCounts();
			if (devices !== null && sequencesMap !== null) {
				void filter();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[deviceFilter, noticableDeviceCount]
	);

	const translations = {
		toggleButtons: {
			due: l10nContext.translate('common.buttonToggle.dashboard.due', 'Fällig'),
			flashy: l10nContext.translate('common.buttonToggle.dashboard.flashy', 'Auffällig'),
			maintenanceLog: l10nContext.translate('common.buttonToggle.dashboard.maintenanceLog', 'Betriebsbuch')
		}
	};

	const deviceFilterButtons: Array<ButtonToggleProps> = [];
	deviceFilterButtons.push({
		buttonText: translations.toggleButtons.due,
		id: DeviceFilter.DUE,
		isActive: false,
		badge: dueDeviceCount
	});
	deviceFilterButtons.push({
		buttonText: translations.toggleButtons.flashy,
		id: DeviceFilter.VALUATION,
		isActive: false,
		badge: noticableDeviceCollectionCount
	});
	deviceFilterButtons.push({
		buttonText: translations.toggleButtons.maintenanceLog,
		id: DeviceFilter.MAINTENANCE_LOG_OPEN,
		isActive: false,
		badge: maintenanceLogEntryDeviceCount
	});

	const deviceFilterButtonSet = deviceFilterButtons.length > 0 ?
		<ButtonToggleSet
			buttons={deviceFilterButtons}
			deSelectAll={false}
			onClick={(id) => {
				setDeviceFilter(id as DeviceFilter);
			}}
		/>
		:
		null;

	const renderDevices = (): JSX.Element => {
		if (effectiveDevices === null) {
			return (
				<LoadingSpinner />
			);
		}

		if (effectiveDevices.length === 0) {
			return (
				<CardEmpty message={l10nContext.translate('common.cards.emptyDefault.device')} />
			);
		}

		return (
			<>
				{
					effectiveDevices.map<JSX.Element>((deviceWithObjectivs) => {
						if (deviceWithObjectivs.objectiveType === ObjectiveType.SEQUENCE) {
							return (
								<CardDeviceWithSequences
									key={deviceWithObjectivs.device.Uuid}
									device={deviceWithObjectivs.device}
									sequences={deviceWithObjectivs.objectives as Array<SequenceViewModel>}
									route={Route.DEVICES}
									onAddRecordClick={onAddSequenceRecord}
									illustration
								/>
							);
						}
						if (deviceWithObjectivs.objectiveType === ObjectiveType.MAINTENANCE_LOG_ENTRY) {
							return (
								<CardDeviceWithMaintenanceLogEntries
									key={deviceWithObjectivs.device.Uuid}
									device={deviceWithObjectivs.device}
									maintenanceLogEntries={deviceWithObjectivs.objectives as Array<MaintenanceLogEntryViewModel>}
									onEditLogEntryClick={onEditMaintenanceLogEntry}
								/>
							);
						}
						return null;
					})
				}
			</>
		);
	};

	return (
		<Expandable expanded>
			<ExpandableHeader caption={l10nContext.translate('view.dashboard.headline.devices', 'Geräte')}>
				<TextLink
					text={l10nContext.translate('common.textlink.all', 'alle')}
					target="/devices"
				/>
				{deviceFilterButtonSet}
			</ExpandableHeader>
			<ExpandableBody>
				<CardCollectionLayout>
					{renderDevices()}
				</CardCollectionLayout>
			</ExpandableBody>
		</Expandable>
	);
};
