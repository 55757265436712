import { L10nContext } from 'context/L10nContext';

import { CardGreeting } from 'presentation/ui/components/cards/card-greeting/CardGreeting';
import { CardNumericBatch } from 'presentation/ui/components/cards/card-numeric-batch/CardNumericBatch';
import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { CardNumericBatchCollection } from 'presentation/ui/compositions/card-numeric-batch-collection/CardNumericBatchCollection';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import { ClearanceCollection } from 'presentation/view/card-collections/device-collection/ClearanceCollection';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from 'services/core/context/AuthContext';
import { ClientContext } from 'services/core/context/ClientContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { SequenceViewModel } from 'services/device/domain/model/SequenceModel';
import { countCombinedNoticableDevices } from 'services/device/lib/dashboard/DeviceDashboardUtil';
import { RecordCreateModal } from 'services/device/presentation/ui/record-action/record-create-modal/RecordCreateModal';

import { RecordCreateModalLocation } from 'services/device/presentation/ui/record-action/record-create-modal/RecordCreateModalLocation';
import { resetActionStatus, selectFilteredClearances, selectFilteredDevices } from 'services/device/store/devicesSlice';
import { resetActionStatus as resetRecordActionStatus } from 'services/device/store/recordSlice';
import { resetActionStatus as resetSequenceActionStatus, selectActiveSequencesByDevices } from 'services/device/store/sequenceSlice';
import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentArchiveModal } from 'services/documents/presentation/ui/document-action/document-archive-modal/DocumentArchiveModal';
import { DocumentDeleteModal } from 'services/documents/presentation/ui/document-action/document-delete-modal/DocumentDeleteModal';
import { DocumentDownloadModal } from 'services/documents/presentation/ui/document-action/document-download-modal/DocumentDownloadModal';
import { DocumentMoveModal } from 'services/documents/presentation/ui/document-action/document-move-modal/DocumentMoveModal';
import { DocumentUnarchiveModal } from 'services/documents/presentation/ui/document-action/document-unarchive-modal/DocumentUnarchiveModal';
import { fetchLatestDocuments, resetActionStatus as resetDocumentActionStatus, selectDocumentsByDate } from 'services/documents/store/documentSlice';
import { MaintenanceLogEntryViewModel } from 'services/maintenance-log/domain/model/MaintenanceLogEntryModel';
import {
	MaintenanceLogResolveModal,
} from 'services/maintenance-log/presentation/ui/maintenance-log-action/maintenance-log-resolve-modal/MaintenanceLogResolveModal';
import {
	resetActionStatus as resetMaintenanceActionStatus,
	selectFilteredMaintenanceLogEntries,
	selectOpenMaintenanceLogEntriesByDevices,
} from 'services/maintenance-log/store/maintenanceLogEntrySlice';
import { DocumentModelState } from '../../services/documents/domain/model/DocumentModelState';
import { CardDocumentAction } from '../ui/components/cards/card-document/CardDocumentActions';
import { DashboardDocumentsCollection } from './card-collections/device-collection/dashboard-documents-collection/DashboardDocumentsCollection';
import { DeviceCollection } from './card-collections/device-collection/DeviceCollection';
import { MaintenanceLogCollection } from './card-collections/device-collection/MaintenanceLogCollection';
import { DashboardViewModalType } from './DashboardViewModalType';

export const DashboardView = (): JSX.Element => {
	const [modalType, setModalType] = useState<DashboardViewModalType>(null);
	const [modalPayload, setModalPayload] = useState<SequenceViewModel | MaintenanceLogEntryViewModel | DocumentViewModel>(null);

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const facilityContext = useContext(FacilityContext);
	const clientContext = useContext(ClientContext);
	const l10nContext = useContext(L10nContext);

	const dispatch = useDispatch();

	// fetch latest documents

	 useEffect(() => {
		dispatch(fetchLatestDocuments({
			clientUuid: clientContext.selectedClientUuid,
			facilityUuid: facilityContext.selectedFacilityUuid
		}));
	});

	// Read the devices from the state store
	const devices = useSelector(selectFilteredDevices(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		true,
		false
	));

	const clearances = useSelector(selectFilteredClearances(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		true,
		false
	));

	const deviceSequencesMap = useSelector(selectActiveSequencesByDevices(devices));
	const clearanceSequencesMap = useSelector(selectActiveSequencesByDevices(clearances));
	const maintenanceLogEntryMap = useSelector(selectOpenMaintenanceLogEntriesByDevices(devices));

	// Read the maintenance logs from the state store
	const maintenanceLogEntries = useSelector(selectFilteredMaintenanceLogEntries(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		true,
		false
	));

	// Read the clearance logs from the state store
	const clearanceEntries = useSelector(selectFilteredClearances(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		true,
		false
	));

	// Read the documents from the state store
	const latestDocuments = useSelector(selectDocumentsByDate(
		clientContext.selectedClientUuid,
		facilityContext.selectedFacilityUuid,
		5
	));

	const noticableDeviceCount = countCombinedNoticableDevices(devices, deviceSequencesMap, maintenanceLogEntryMap);
	const noticableClearanceCount = countCombinedNoticableDevices(clearanceEntries, clearanceSequencesMap);

	const onModalDismiss = () => {
		setModalType(null);
		setModalPayload(null);
	};

	const onModalTriggerClick = (type: DashboardViewModalType, payload: SequenceViewModel | MaintenanceLogEntryViewModel | DocumentViewModel) => {
		dispatch(resetActionStatus());
		dispatch(resetMaintenanceActionStatus());
		dispatch(resetSequenceActionStatus());
		dispatch(resetRecordActionStatus());
		dispatch(resetDocumentActionStatus());

		setModalType(type);
		setModalPayload(payload);
	};

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name
	};

	const renderNumericBatchCards = (): JSX.Element => {
		const deviceCardLabel = noticableDeviceCount === 1 ? 'cardNumericBatch.devices.single' : 'cardNumericBatch.devices.multiple';
		const clearanceCardLabel = noticableClearanceCount === 1 ? 'cardNumericBatch.clearances.single' : 'cardNumericBatch.clearances.multiple';
		const maintenanceLogEntryCardLabel = maintenanceLogEntries.length === 1 ? 'cardNumericBatch.maintenanceLogEntries.single' : 'cardNumericBatch.maintenanceLogEntries.multiple';

		return (
			<>
				<CardNumericBatch
					count={noticableDeviceCount}
					label={l10nContext.translate(deviceCardLabel)}
				/>
				<CardNumericBatch
					count={noticableClearanceCount}
					label={l10nContext.translate(clearanceCardLabel)}
				/>
				<CardNumericBatch
					count={maintenanceLogEntries.length}
					label={l10nContext.translate(maintenanceLogEntryCardLabel)}
				/>
			</>
		);
	};

	const renderModal = (): JSX.Element => {
		let dashBoardModal: JSX.Element = null;

		switch (modalType) {
			case DashboardViewModalType.CREATE_RECORD:
				dashBoardModal =
					<RecordCreateModal
						sequence={modalPayload as SequenceViewModel}
						location={RecordCreateModalLocation.DASHBOARD}
						onDismiss={onModalDismiss}
					/>;
				break;

			case DashboardViewModalType.RESOLVE_MAINTENANCE_LOG:
				dashBoardModal =
					<MaintenanceLogResolveModal
						selectedMaintenanceLogEntry={modalPayload as MaintenanceLogEntryViewModel}
						onDismiss={onModalDismiss}
					/>;
				break;
			case DashboardViewModalType.UNARCHIVE_DOCUMENT:
				dashBoardModal =
					<DocumentUnarchiveModal
						onDismiss={onModalDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
			case DashboardViewModalType.ARCHIVE_DOCUMENT:
				dashBoardModal =
					<DocumentArchiveModal
						onDismiss={onModalDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
			case DashboardViewModalType.DOWNLOAD_DOCUMENT:
				dashBoardModal =
					<DocumentDownloadModal
						onDismiss={onModalDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
			case DashboardViewModalType.DELETE_DOCUMENT:
				dashBoardModal =
					<DocumentDeleteModal
						onDismiss={onModalDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
			case DashboardViewModalType.MOVE_DOCUMENT:
				dashBoardModal =
					<DocumentMoveModal
						onDismiss={onModalDismiss}
						document={modalPayload as DocumentViewModel}
					/>;
				break;
		}

		return dashBoardModal;
	};

	const renderDocuments = () => {
		return (
			<DashboardDocumentsCollection
				documents={latestDocuments}
				// fetchStatus={documentStorePending}
				onAction={(payload, document) => {
					switch (payload.type) {
						case CardDocumentAction.MOVE:
							return onModalTriggerClick(DashboardViewModalType.MOVE_DOCUMENT, document);
						case CardDocumentAction.ARCHIVE:
							if (document.State === DocumentModelState.ARCHIVED) {
								return onModalTriggerClick(DashboardViewModalType.UNARCHIVE_DOCUMENT, document);
							}

							return onModalTriggerClick(DashboardViewModalType.ARCHIVE_DOCUMENT, document);
						case CardDocumentAction.DELETE:
							return onModalTriggerClick(DashboardViewModalType.DELETE_DOCUMENT, document);
						case CardDocumentAction.DOWNLOAD:
							return onModalTriggerClick(DashboardViewModalType.DOWNLOAD_DOCUMENT, document);
						default:
							return null;
					}
				}}
			/>
		);
	};

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs />
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_MAIN}>
				<ViewLayout>
					<ViewLayoutSection>
						<CardGreeting user={user.name} />

						<CardNumericBatchCollection>
							{renderNumericBatchCards()}
						</CardNumericBatchCollection>
					</ViewLayoutSection>

					<ViewLayoutSection>
						{renderModal()}

						<DeviceCollection
							noticableDeviceCount={noticableDeviceCount}
							onAddSequenceRecord={(sequence) => {
								onModalTriggerClick(DashboardViewModalType.CREATE_RECORD, sequence);
							}}
							onEditMaintenanceLogEntry={(maintenanceLogEntry) => {
								onModalTriggerClick(DashboardViewModalType.RESOLVE_MAINTENANCE_LOG, maintenanceLogEntry);
							}}
						/>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ClearanceCollection
							noticableClearanceCount={noticableClearanceCount}
							onClick={onModalTriggerClick}
						/>
					</ViewLayoutSection>

					<ViewLayoutSection>
						<MaintenanceLogCollection onClick={onModalTriggerClick} />
					</ViewLayoutSection>

					<ViewLayoutSection>
						{renderDocuments()}
					</ViewLayoutSection>
				</ViewLayout>

			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>
		</MainLayout>
	);
};
