/* eslint-disable max-classes-per-file */
import { Optional } from '../common/misc/Optional';
import { ProductGroup } from './ProductGroup';

export class ProductGroupDefinition {

	private readonly productGroup: ProductGroup;

	private readonly labelKey: string;

	constructor(productGroup: ProductGroup, labelKey: string) {
		this.productGroup = productGroup;
		this.labelKey = labelKey;
	}

	public getProductGroup(): ProductGroup {
		return this.productGroup;
	}

	public getLabelKey(): string {
		return this.labelKey;
	}

	public toString = (): string => {
		return this.productGroup.toString();
	}

}

export const NUCLEAR_MEDICINE: ProductGroupDefinition = new ProductGroupDefinition(
	ProductGroup.NUCLEAR_MEDICINE,
	'inventory.productGroup.nuclearMedicine'
);

export const ROTOP: ProductGroupDefinition = new ProductGroupDefinition(
	ProductGroup.ROTOP,
	'inventory.productGroup.rotop'
);

export const CURIUM: ProductGroupDefinition = new ProductGroupDefinition(
	ProductGroup.CURIUM,
	'inventory.productGroup.curium'
);

export const RADIOLOGY: ProductGroupDefinition = new ProductGroupDefinition(
	ProductGroup.RADIOLOGY,
	'inventory.productGroup.radiology'
);

export const CLEARANCE: ProductGroupDefinition = new ProductGroupDefinition(
	ProductGroup.CLEARANCE,
	'inventory.productGroup.clearance'
);

export const GENERIC_SEQUENCES: ProductGroupDefinition = new ProductGroupDefinition(
	ProductGroup.GENERIC_SEQUENCES,
	'inventory.productGroup.genericSequences'
);

export function getProductGroupDefinition(productGroup: ProductGroup): ProductGroupDefinition {
	const productGroupDefinition: Optional<ProductGroupDefinition> = getAllProductGroupDefinitions().find((definition) => definition.getProductGroup() === productGroup) ?? null;
	if (productGroupDefinition === null) {
		throw new Error('Missing product group definition: ' + productGroup);
	}
	return productGroupDefinition;
}

export function getProductGroupDefinitions(productGroups: ReadonlyArray<ProductGroup>): ReadonlyArray<ProductGroupDefinition> {
	return productGroups.map((productGroup) => getProductGroupDefinition(productGroup));
}

export function getAllProductGroupDefinitions(): ReadonlyArray<ProductGroupDefinition> {
	return [
		NUCLEAR_MEDICINE,
		ROTOP,
		CURIUM,
		RADIOLOGY,
		CLEARANCE,
		GENERIC_SEQUENCES
	];
}

export function getAllProductGroups(): ReadonlyArray<ProductGroup> {
	return [
		ProductGroup.NUCLEAR_MEDICINE,
		ProductGroup.ROTOP,
		ProductGroup.CURIUM,
		ProductGroup.RADIOLOGY,
		ProductGroup.CLEARANCE,
		ProductGroup.GENERIC_SEQUENCES
	];
}